
import { Ref, computed, defineComponent, reactive, ref, toRef, toRefs, watch } from 'vue';
import AuthModal from './AuthModal.vue';
import { Notify } from '@redviking/argonaut-core-ui/src/notifications';
import { refetchAllQueries } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { accessor } from '../../store';
import { router } from '../../routing/router';
import {
    getAvailableContexts,
    getSwitchableContexts,
    providerLocalLogin,
    refreshTokenRevoke,
    setContext,
} from '@redviking/argonaut-core-ui/src/auth/api/auth';
import { useRoute } from '@redviking/argonaut-core-ui/src/util/composables';
import { apiTrpcHttpClient } from '../../util/api-trpc';
import { messages } from 'src/i18n/i18n';


export type AuthContext = {
  id: string;
  name: string;
};

type FormState = {
    username: Ref<string>,
    password: Ref<string>,
    signingOut: Ref<boolean>,
    showPassword: Ref<boolean>,
    renderForm: Ref<boolean>,
};

type ContextState = {
    renderContextForm: Ref<boolean>,
    contexts: Ref<{
        name: string;
        description: string;
        id: string;
        __typename?: 'argo_context' | undefined;
    }[]>,
    context: Ref<string>,
    contextsLoading: Ref<boolean>,
};

/** null if unknown, true if success, false if failed, string if failed with reason */
type SignInResult = Ref<null | boolean | string>;

export default defineComponent({
    components: {
        AuthModal,
    },
    setup () {
        const formState: FormState = {
            username: ref(''),
            password: ref(''),
            signingOut: ref(false),
            showPassword: ref(false),
            renderForm: ref(false),
        };
        const contextState: ContextState = {
            renderContextForm: ref(false),
            contexts: ref([]),
            contextsLoading: ref(false),
            context: ref(''),
        };

        const welcomeText = computed(() => {
            if (accessor.auth.currentUser) {
                return messages.welcomeUser({ currentUser: accessor.auth.currentUser });
            }
            return '';
        });
        /**
         * vuetify validators are used for form input.
         * these fields are required: username, password, context.
         */
        const formRules = {
            required: (value: string) => Boolean(value) || 'Required',
        };

        function useSignIn () {
            const setSignedInUser = accessor.auth.setSignedInUser;

            /**
             * display the sign in result.
             */
            const signInResult: SignInResult = ref(null);

            /** display a loading indicator when the sign in request is in progress */
            const loading = ref(false);

            /**
             * Get the user id. Server will know us by our session.
             * Send the user id and context to the store, which triggers fetching ACLs.
             */
            async function fetchCurrentUser () {
                await setSignedInUser({ refetchAllQueries: true });
            }

            // when the sign in result becomes true, refetch user stuff
            watch(signInResult, () => {
                if (signInResult.value === true) { // just signed in successfully
                    fetchCurrentUser().catch(err => {
                        Notify.error('Failed to load user info', err);
                    });
                }
            });

            return {
                /**
                 * Start with loading set to true, and sign in result to null (since the result is no longer known).
                 * Make a sign in request from the form inputs.
                 * If the response has a message, show it to the user.
                 */
                async signIn () {
                    if (loading.value) {
                        return;
                    }

                    loading.value = true;
                    signInResult.value = null;

                    try {
                        await providerLocalLogin(
                            formState.username.value,
                            formState.password.value
                        );

                        // Now use the "partial_token" that we got during login
                        // (an http-only cookie that we can't read) to load the
                        // list of contexts that the user can choose from.
                        contextState.renderContextForm.value = true;
                        contextState.contexts.value = await getAvailableContexts();
                        if (contextState.contexts.value.length === 1) {
                            contextState.context.value = contextState.contexts.value[0].id;
                            await setContext(contextState.context.value, 'partialJwt');
                            await accessor.auth.refreshToken();
                            signInResult.value = true;
                        }
                    } catch (err) {
                        console.error(err);
                        // eslint-disable-next-line require-atomic-updates
                        signInResult.value = err.message;
                    }
                    // eslint-disable-next-line require-atomic-updates
                    loading.value = false;
                },
                async signInContext () {
                    if (loading.value) {
                        return;
                    }

                    loading.value = true;
                    signInResult.value = null;

                    try {
                        await setContext(contextState.context.value, accessor.auth.currentUser ? 'hasuraJwt' : 'partialJwt');

                        // Now use the "refresh_token" that we got during login
                        // (an http-only cookie that we can't read) to refresh our token
                        // which also includes other service tokens in the response body.
                        const tokenSuccess = await accessor.auth.refreshToken();

                        signInResult.value = tokenSuccess ? tokenSuccess : 'Invalid Attempt to Set Context';
                    } catch (err) {
                        console.error(err);
                        signInResult.value = err.message;
                    }
                    refetchAllQueries();
                    // eslint-disable-next-line require-atomic-updates
                    loading.value = false;
                },
                async signInMsal () {
                    if (loading.value) {
                        return;
                    }

                    loading.value = true;
                    signInResult.value = null;

                    try {
                        const msalResponse = await apiTrpcHttpClient.auth.provider.msal.login.query().catch(err => {
                            Notify.error(err.message);
                            throw err;
                        });
                        msalResponse.redirect && window.location.replace(msalResponse.redirect);
                    } catch (err) {
                        console.error(err);
                        signInResult.value = err.message;
                    }
                },

                async openContextMenu () {
                    signInVisible.value = true;
                    contextState.renderContextForm.value = true;
                    contextState.contexts.value = await getSwitchableContexts();
                },
                signInResult,
                loading,
            };
        }

        const currentRoute = useRoute();

        function useSignOut (signInResult: SignInResult) {
            const signOutReason = ref('');

            watch(signInResult, (signInResultValue) => {
                if (signInResultValue) {
                    signOutReason.value = '';
                }
            });

            async function signOut () {
                formState.signingOut.value = true;
                try {
                    await refreshTokenRevoke();
                    accessor.auth.setAuthStatusFalse();
                    signOutReason.value = 'signOutReasons.default';
                    if (currentRoute.value.name !== 'home') {
                        router.push({ name: 'home' });
                    }
                    accessor.notify.clearNotifications();
                    Notify.info('You have been signed out');
                    formState.signingOut.value = false;
                } catch (err) {
                    formState.signingOut.value = false;
                    throw err;
                }
            }

            return {
                signOutReason,
                signOut,
            };
        }

        // refetch queries when the logged in user changes (sign in/sign out)
        watch(computed(() => accessor.auth.currentUser), () => {
            refetchAllQueries();
        });

        /**
         * Hides the modal after a successful sign in or sign out.
         * Picks which modal to show when the activator is clicked.
         * Resets the signInResult when the modal is hidden.
         * Clears the form inputs when the modal is hidden.
         */
        function useVisibilityControl (signInResult: SignInResult, signOutReason: Ref<string>) {
            const visibility = reactive({
                signIn: false,
                signOut: false,
            });

            const vr = toRefs(visibility);
            let hideTimeout: null | ReturnType<typeof setTimeout> = null;
            watch([ signInResult, signOutReason ], () => {
                if (signInResult.value === true || signOutReason.value !== '') {
                    if (hideTimeout) {
                        clearTimeout(hideTimeout);
                    }
                    hideTimeout = setTimeout(() => {
                        visibility.signIn = false;
                        visibility.signOut = false;
                    }, 1000);
                }
            });

            /** clear the input fields after the sign out is hidden */
            let clearFieldsTimeout: null | ReturnType<typeof setTimeout> = null;
            watch([ vr.signIn, vr.signOut ], () => {
                if (hideTimeout) {
                    clearTimeout(hideTimeout);
                }

                // render fields when sign in is visible
                if (vr.signIn.value) {
                    // Either the Form should be rendered, or the context selector
                    formState.renderForm.value = true;
                    if (clearFieldsTimeout) {
                        clearTimeout(clearFieldsTimeout);
                    }
                } else {
                    // clear the sign in result immediately when hidden
                    if (clearFieldsTimeout) {
                        clearTimeout(clearFieldsTimeout);
                    }
                    clearFieldsTimeout = setTimeout(() => {
                        signInResult.value = null;
                        formState.renderForm.value = false;
                        formState.username.value = '';
                        formState.password.value = '';
                        contextState.contexts.value = [];
                        contextState.renderContextForm.value = false;
                        contextState.context.value = '';
                    }, 1000);
                }
            });

            function activatorClicked () {
                if (visibility.signIn || visibility.signOut) { // if either modals are shown, hide them
                    visibility.signIn = false;
                    visibility.signOut = false;
                } else if (accessor.auth.currentUser) {
                    visibility.signOut = true;
                } else {
                    visibility.signIn = true;
                }
            }

            return {
                signInVisible: toRef(visibility, 'signIn'),
                signOutVisible: toRef(visibility, 'signOut'),
                activatorClicked,
            };
        }

        const usingSignIn = useSignIn();
        const usingSignOut = useSignOut(usingSignIn.signInResult);
        const { signInVisible, ...usingVisbilityControl } = useVisibilityControl(usingSignIn.signInResult, usingSignOut.signOutReason);
        const changeContext = async (context: AuthContext) => {
            await apiTrpcHttpClient.auth.token.changeContext.query({
                contextId: context.id,
            });
            await accessor.auth.refreshToken();
            window.location.reload();
        };

        return {
            ...formState,
            ...contextState,
            welcomeText,
            formRules,
            signInVisible,
            ...usingSignIn,
            ...usingSignOut,
            ...usingVisbilityControl,
            changeContext,
        };
    },
});
