import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';

export function getUnlinkedCells (opts: {
    screen: Latest.Screen.Config,
}): {
    argoCell: Latest.Screen.Cells.AppletCell;
    usesInAspectRatios: Latest.Screen.Designer.GridLayoutAspectRatio[];
}[] {
    const allLinkedGridCellsFromEnabledAspectRatios = Object.values(opts.screen.gridLayoutAspectRatios).filter(gLAR => gLAR.enabled).flatMap(gridLayout => gridLayout.linkedGridCells);
    const nonEnabledGridLayouts = Object.entries(opts.screen.gridLayoutAspectRatios).filter(([ _, gLAR ]) => !gLAR.enabled);
    const ret: {
        argoCell: Latest.Screen.Cells.AppletCell;
        usesInAspectRatios: Latest.Screen.Designer.GridLayoutAspectRatio[];
    }[] = [];
    opts.screen.cells.forEach(cell => {
        if (!allLinkedGridCellsFromEnabledAspectRatios.find(lgc => lgc.type === 'regular' && lgc.additionalCellProperties.argoCellId === cell.id)) {
            const item: {
                argoCell: Latest.Screen.Cells.AppletCell;
                usesInAspectRatios: Latest.Screen.Designer.GridLayoutAspectRatio[];
            } = {
                argoCell: cell,
                usesInAspectRatios: [],
            };
            for (const [ aspectRatio, gLAR ] of nonEnabledGridLayouts) {
                if (gLAR.linkedGridCells.find(lgc => lgc.type === 'regular' && lgc.additionalCellProperties.argoCellId === cell.id)) {
                    item.usesInAspectRatios.push(aspectRatio as Latest.Screen.Designer.GridLayoutAspectRatio);
                }
            }
            ret.push(item);
        }
    });
    return ret;
}

export function getUnlinkedCell (opts: {
    screen: Latest.Screen.Config,
}): Latest.Screen.Config['cells'][number] | null {
    const allLinkedGridCells = Object.values(opts.screen.gridLayoutAspectRatios).filter(gLAR => gLAR.enabled).flatMap(gridLayout => gridLayout.linkedGridCells);
    /** the first cell in the screen that is not found in any of the linked grid cells */
    const unlinkedCell = opts.screen.cells.find(cell => !allLinkedGridCells.find(lgc => lgc.type === 'regular' && lgc.additionalCellProperties.argoCellId === cell.id));
    return unlinkedCell || null;
}
