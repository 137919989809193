/* eslint-disable max-lines */
import {
    type AnyNodeCfg,
    type FlowCfg,
    type NodeCfg,
    type NodeKind,
    nodeCfgSchemaMap,
} from '@redviking/argonaut-util/types/mes/applet-designs/flow.latest';
import { getUID } from '@redviking/argonaut-util/src/getUID';
import { UiFlowNodeControl } from './controls/UiFlowNodeControl';
import { SocketMapPerNode } from '@redviking/applet-runtime/src/flow/sockets';
import type { UiFlowNode } from './UiFlowNode';
import { ClassicPreset } from 'rete';
import { UiFlowNodeSocket } from './sockets/UiFlowNodeSocket';
import { UiFlowNodeOutput } from './sockets/UiFlowNodeOutput';
import { removeNodeSocket } from './sync-utils/remove-node-socket';
import { UiFlowNodeInput } from './sockets/UiFlowNodeInput';
import { getMetricExpr } from '@redviking/argonaut-util/src/metric-parser/metric-expr';
import { validateSparkplugMetricExpr } from '../validations/metric-expr.validations';
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import { getAppletEvents } from '@redviking/argonaut-util/src/mes/get-applet-events';

export type NodeCategory = 'text' | 'number' | 'conditional' | 'variable' | 'events' | 'argonautApi' | 'external' | 'timeAndDate' | 'other' | 'boolean';

type NodeControlValues<CFG extends AnyNodeCfg> = CFG extends { controlValues: Record<string, unknown> }
    ? {
        [controlKey in keyof CFG['controlValues']]: UiFlowNodeControl<CFG['controlValues'][controlKey]>;
    }
    : Record<string, never>;

export type NodeVaildation<KIND extends NodeKind> = (node: NodeCfg<KIND>, flow: FlowCfg) => true | {[controlName: string]: string};

export type NodeInitializer<
    KIND extends NodeKind,
    CFG extends NodeCfg<KIND> = NodeCfg<KIND>,
    SOCKETS extends SocketMapPerNode[KIND] = SocketMapPerNode[KIND],
> = {
    cfg: () => NodeCfg<KIND>,
    nodeCategory: NodeCategory;
    supressNodeCategory?: boolean;
    controls: (cfg: CFG, node: UiFlowNode<KIND, CFG>) => NodeControlValues<CFG>;
    inputs: (cfg: CFG, node: UiFlowNode<KIND, CFG>) => ({
        [inputKey in keyof SOCKETS['inputs']]: ClassicPreset.Input<UiFlowNodeSocket>;
    }),
    kindLabel: string;
    canDelete?: boolean | ((cfg: CFG, node?: UiFlowNode<KIND, CFG>) => boolean);
    canResize?: boolean | ((cfg: CFG, node: UiFlowNode<KIND, CFG>) => boolean);
    outputs: (cfg: CFG, node: UiFlowNode<KIND, CFG>) => ({
        [outputKey in keyof SOCKETS['outputs']]: ClassicPreset.Output<UiFlowNodeSocket>;
    }),
    // record is keyed by control name and values are error messages
    validator: NodeVaildation<KIND>;
};

/**
 * config initializer for each node kind.
 */
export const nodeKindMap: {
    [kind in NodeKind]: NodeInitializer<kind>;
} = {
    appletEventEmit: {
        cfg: () => ({ controlValues: { appletEventId: '' }, id: getUID(), kind: 'appletEventEmit' }),
        controls: (cfg, node) => ({
            appletEventId: new UiFlowNodeControl({ cfg: { kind: 'selectEvent' }, node, value: cfg.controlValues.appletEventId }),
        }),
        inputs: () => ({ trigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Trigger') }),
        kindLabel: 'Emit applet event',
        nodeCategory: 'events',
        outputs: () => ({ next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next') }),
        validator: (node, flow) => {
            const entityData = accessor.entityAsType('appletDesignVersion');
            const events = entityData ? getAppletEvents(entityData.config) : {};
            const validEventNames = Object.keys(events);
            const errors: Record<string, string> = {};
            if (!node.controlValues.appletEventId) {
                errors.appletEventId = 'Event ID is required';
            }
            if (!validEventNames.includes(node.controlValues.appletEventId)) {
                errors.appletEventId = 'Event ID is not a valid event';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'trigger')) {
                errors.trigger = 'Trigger input is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    appletEventListen: {
        cfg: () => ({ controlValues: { appletEventId: '' }, id: getUID(), kind: 'appletEventListen' }),
        controls: (cfg, node) => ({ appletEventId: new UiFlowNodeControl({ cfg: { kind: 'selectEvent' }, node, value: cfg.controlValues.appletEventId }) }),
        inputs: () => ({}),
        kindLabel: 'Listen for applet event',
        nodeCategory: 'events',
        outputs: () => ({ event: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Event') }),
        validator: (node, flow) => {
            const entityData = accessor.entityAsType('appletDesignVersion');
            const events = entityData ? getAppletEvents(entityData.config) : {};
            const validEventNames = Object.keys(events);
            const errors: Record<string, string> = {};
            if (!node.controlValues.appletEventId) {
                errors.appletEventId = 'Event ID is required';
            }
            if (!validEventNames.includes(node.controlValues.appletEventId)) {
                errors.appletEventId = 'Event ID is not a valid event';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'event')) {
                errors.event = 'Event output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    boolCast: {
        cfg: () => ({ controlValues: {}, id: getUID(), kind: 'boolCast' }),
        controls: (_cfg, _node) => {
            return {};
        },
        inputs: () => ({ input: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Input') }),
        kindLabel: 'Boolean Value Cast',
        nodeCategory: 'boolean',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('boolean'), 'Output') }),
        validator: () => true,
    },
    comparisonNumber: {
        cfg: () => ({ controlValues: { not: false, operation: '=' }, id: getUID(), kind: 'comparisonNumber' }),
        controls: (cfg, node) => {
            return {
                not: new UiFlowNodeControl({ cfg: { kind: 'boolean', label: 'Not' }, node, value: cfg.controlValues.not }),
                operation: new UiFlowNodeControl({
                    cfg: {
                        items: [
                            { label: '=', value: '=' },
                            { label: '>', value: '>' },
                            { label: '>=', value: '>=' },
                            { label: '<', value: '<' },
                            { label: '<=', value: '<=' },
                        ],
                        kind: 'selection',
                        label: 'Operation',
                    },
                    node,
                    value: cfg.controlValues.operation,
                }),
            };
        },
        inputs: () => ({
            input1: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Input 1'),
            input2: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Input 2'),
        }),
        kindLabel: 'Number comparison',
        nodeCategory: 'conditional',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('boolean'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};

            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input1')) {
                errors.input1 = 'Input 1 is required';
            }

            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input2')) {
                errors.input2 = 'Input 2 is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    comparisonString: {
        cfg: () => ({ controlValues: { not: false, operation: '=' }, id: getUID(), kind: 'comparisonString' }),
        controls: (cfg, node) => ({
            not: new UiFlowNodeControl({ cfg: { kind: 'boolean', label: 'Not' }, node, value: cfg.controlValues.not }),
            operation: new UiFlowNodeControl({
                cfg: {
                    items: [
                        { label: '=', value: '=' },
                        { label: 'contains', value: 'contains' },
                        { label: 'startsWith', value: 'startsWith' },
                        { label: 'endsWith', value: 'endsWith' },
                    ],
                    kind: 'selection',
                    label: 'Operation',
                },
                node,
                value: cfg.controlValues.operation,
            }),
        }),
        inputs: () => ({
            input1: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Input 1'),
            input2: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Input 2'),
        }),
        kindLabel: 'Text comparison',
        nodeCategory: 'conditional',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('boolean'), 'Output') }),
        validator: (node, flow) => {

            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input1')) {
                errors.input1 = 'Input 1 is required';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input2')) {
                errors.input2 = 'Input 2 is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    createMaterial: {
        cfg: () => ({
            controlValues: {
                allowExistingMaterial: true,
                attributes: {},
                labelPrint: false,
                location: { id: '' },
                materialClassId: '',
                materialModelId: '',
                snCfg: { kind: 'direct' },
                udfs: {},
            },
            id: getUID(),
            kind: 'createMaterial',
        }),
        controls: (cfg, node) => ({
            allowExistingMaterial: new UiFlowNodeControl({ cfg: { kind: 'boolean', label: 'Allow Existing Material' }, node, value: cfg.controlValues.allowExistingMaterial }),
            attributes: new UiFlowNodeControl({ cfg: { kind: 'createMaterial.attributes', label: 'Attributes' }, node, value: cfg.controlValues.attributes }),
            labelPrint: new UiFlowNodeControl({ cfg: { kind: 'boolean', label: 'Label Print' }, node, value: cfg.controlValues.labelPrint }),
            location: new UiFlowNodeControl({ cfg: { kind: 'location', label: 'Location' }, node, value: cfg.controlValues.location }),
            materialClassId: new UiFlowNodeControl({ cfg: { kind: 'createMaterial.classId', label: 'Material Class' }, node, value: cfg.controlValues.materialClassId }),
            materialModelId: new UiFlowNodeControl({ cfg: { kind: 'createMaterial.modelId', label: 'Material Model' }, node, value: cfg.controlValues.materialModelId }),
            snCfg: new UiFlowNodeControl({ cfg: { kind: 'createMaterial.snCfg', label: 'Serial Number' }, node, value: cfg.controlValues.snCfg }),
            udfs: new UiFlowNodeControl({ cfg: { kind: 'createMaterial.udfs', label: 'UDFs' }, node, value: cfg.controlValues.udfs }),
        }),
        inputs: cfg => {
            const inputs = {} as ReturnType<NodeInitializer<'createMaterial'>['inputs']>;
            if (cfg.controlValues.snCfg.kind === 'direct') {
                inputs.sn = new ClassicPreset.Input(new UiFlowNodeSocket('string'), 'Serial Number');
            } else {
                for (const fmtInput of cfg.controlValues.snCfg.fmtInputs) {
                    inputs[`snInput_${fmtInput}`] = new ClassicPreset.Input(new UiFlowNodeSocket('string'), fmtInput);
                }
            }
            for (const udfId in cfg.controlValues.udfs) {
                inputs[`udfInput_${udfId}`] = new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), cfg.controlValues.udfs[udfId].name);
            }
            return inputs;
        },
        kindLabel: 'Create material',
        nodeCategory: 'argonautApi',
        outputs: () => ({
            next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next'),
            materialId: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Material ID'),
            serialNumber: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Serial Number'),
        }),
        validator: node => {

            const errors: Record<string, string> = {};
            if (!node.controlValues.location) {
                errors.location = 'Location is required';
            }
            if (!node.controlValues.materialClassId) {
                errors.materialClassId = 'Material Class is required';
            }
            if (!node.controlValues.materialModelId) {
                errors.materialModelId = 'Material Model is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    defaultVal: {
        cfg: () => ({ id: getUID(), kind: 'defaultVal' }),
        controls: () => ({}),
        inputs: () => ({
            defaultValue: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Default'),
            input: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Input'),
        }),
        kindLabel: 'Default value',
        nodeCategory: 'variable',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('varValue'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'defaultValue')) {
                errors.defaultValue = 'Default value is required';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input')) {
                errors.input = 'Input is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    eventDelay: {
        cfg: () => ({ controlValues: { delayMs: 1000 }, id: getUID(), kind: 'eventDelay' }),
        controls: (cfg, node) => ({ delayMs: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Delay (ms)', min: 0 }, node, value: cfg.controlValues.delayMs }) }),
        inputs: () => ({ input: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Input') }),
        kindLabel: 'Event delay',
        nodeCategory: 'events',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input')) {
                errors.input = 'Input value is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    eventOneShot: {
        cfg: () => ({ id: getUID(), kind: 'eventOneShot' }),
        controls: () => ({}),
        inputs: () => ({
            input: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Input'),
            reset: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Reset'),
        }),
        kindLabel: 'Event one shot',
        nodeCategory: 'events',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input')) {
                errors.input = 'Input value is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    eventSwitch: {
        cfg: () => ({ id: getUID(), kind: 'eventSwitch' }),
        controls: () => ({}),
        inputs: () => ({
            event: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Event'),
            condition: new ClassicPreset.Input(new UiFlowNodeSocket('boolean'), 'Condition'),
        }),
        kindLabel: 'Event switch',
        nodeCategory: 'events',
        outputs: () => ({
            false: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'False'),
            true: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'True'),
        }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'event')) {
                errors.event = 'Event is required';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'condition')) {
                errors.condition = 'Condition is required';
            }

            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'true') && !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'false')) {
                errors.true = 'At least one output is required';
                errors.false = 'At least one output is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    eventThrottle: {
        cfg: () => ({ controlValues: { max: 1, windowMs: 200 }, id: getUID(), kind: 'eventThrottle' }),
        controls: (cfg, node) => ({
            max: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Max', min: 1 }, node, value: cfg.controlValues.max }),
            windowMs: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Window (ms)', min: 0 }, node, value: cfg.controlValues.windowMs }),
        }),
        inputs: () => ({ input: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Input') }),
        kindLabel: 'Event throttle',
        nodeCategory: 'events',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input')) {
                errors.input = 'Input is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    jsonParse: {
        cfg: () => ({ controlValues: { outputExpressions: [] }, id: getUID(), kind: 'jsonParse' }),
        controls: (cfg, node) => ({
            outputExpressions: new UiFlowNodeControl({
                cfg: {
                    kind: 'button',
                    label: 'Add Output',
                    onClick: () => node.applyControlValue('outputExpressions', [ ...cfg.controlValues.outputExpressions, '' ]),
                },
                node,
                value: cfg.controlValues.outputExpressions,
            }),
        }),
        inputs: () => ({ input: new ClassicPreset.Input(new UiFlowNodeSocket('string'), 'Input') }),
        kindLabel: 'JSON parse',
        nodeCategory: 'variable',
        outputs: (cfg, node) => {
            const outputs: Record<`output${number}`, ClassicPreset.Output<UiFlowNodeSocket>> = {};
            for (let i = 0; i < cfg.controlValues.outputExpressions.length; i++) {
                const control = new UiFlowNodeControl({
                    cfg: {
                        kind: 'jsonParse.outputExpression',
                        onDelete () {
                            removeNodeSocket({
                                newNodeCfg: {
                                    ...node.cfg,
                                    controlValues: {
                                        ...node.cfg.controlValues,
                                        outputExpressions: [
                                            ...node.cfg.controlValues.outputExpressions.slice(0, i),
                                            ...node.cfg.controlValues.outputExpressions.slice(i + 1),
                                        ],
                                    },
                                },
                                node,
                                port: 'outputs',
                                portSocketsTotal: cfg.controlValues.outputExpressions.length,
                                socketToRemove: {
                                    idx: i,
                                    nameFn: (idx: number): `output${number}` => `output${idx}`,
                                },
                            });
                        },
                        placeholder: 'JSONata Expression',
                    },
                    node,
                    value: cfg.controlValues.outputExpressions[i],
                });
                control.changeCb = value => {
                    node.cfg.controlValues.outputExpressions[i] = value;
                    node.cfg.controlValues.outputExpressions = [ ...node.cfg.controlValues.outputExpressions ];
                    node.applyControlValue('outputExpressions', node.cfg.controlValues.outputExpressions);
                };
                outputs[`output${i}`] = new UiFlowNodeOutput({
                    control: control as UiFlowNodeControl<unknown>,
                    socket: new UiFlowNodeSocket('varValue'),
                });
            }
            return outputs;
        },
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!node.controlValues.outputExpressions.length) {
                errors.outputExpressions = 'At least one output expression is required';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input')) {
                errors.input = 'Input is required';
            }

            let atLeastOneOutput = false;
            for (let i = 0; i < node.controlValues.outputExpressions.length; i++) {
                const jsonExpression = node.controlValues.outputExpressions[i];
                if (!jsonExpression) {
                    errors[`output${i}`] = 'Output expression is required';
                } else {
                    // I think there is a call to safe parse on the entire config that is occuring elsewhere essentially duplicating this check
                    const parseResult = nodeCfgSchemaMap.jsonParse.shape.controlValues.shape.outputExpressions.safeParse(jsonExpression);
                    if (parseResult.success === false) {
                        errors[`output${i}`] = 'This is not a valid JSONata expression';
                    }
                }
                if (Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === `output${i}`)) {
                    atLeastOneOutput = true;
                }
            }

            if (!atLeastOneOutput) {
                for (let i = 0; i < node.controlValues.outputExpressions.length; i++) {
                    errors[`output${i}`] = 'At least one output is required';
                }
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    mqttPublish: {
        cfg: () => ({ controlValues: { qos: 0, retain: false }, id: getUID(), kind: 'mqttPublish' }),
        controls: (cfg, node) => ({
            qos: new UiFlowNodeControl({
                cfg: {
                    items: [
                        { label: '0', value: 0 },
                        { label: '1', value: 1 },
                        { label: '2', value: 2 },
                    ],
                    kind: 'selection',
                    label: 'QoS',
                },
                node,
                value: cfg.controlValues.qos,
            }),
            retain: new UiFlowNodeControl({ cfg: { kind: 'boolean', label: 'Retain' }, node, value: cfg.controlValues.retain }),
        }),
        inputs: () => ({
            trigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Trigger'),
            payload: new ClassicPreset.Input(new UiFlowNodeSocket('string'), 'Message'),
            topic: new ClassicPreset.Input(new UiFlowNodeSocket('string'), 'Topic'),
        }),
        kindLabel: 'MQTT publish',
        nodeCategory: 'external',
        outputs: () => ({
            next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next'),
        }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'trigger')) {
                errors.trigger = 'Trigger is required';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'payload')) {
                errors.payload = 'Payload is required';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'topic')) {
                errors.topic = 'Topic is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    mqttSubscribe: {
        cfg: () => ({ controlValues: { qos: 0, topic: '' }, id: getUID(), kind: 'mqttSubscribe' }),
        controls: (cfg, node) => ({
            qos: new UiFlowNodeControl({
                cfg: {
                    items: [
                        { label: '0', value: 0 },
                        { label: '1', value: 1 },
                        { label: '2', value: 2 },
                    ],
                    kind: 'selection',
                    label: 'QoS',
                },
                node,
                value: cfg.controlValues.qos,
            }),
            topic: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'Topic' }, node, value: cfg.controlValues.topic }),
        }),
        inputs: () => ({}),
        kindLabel: 'MQTT subscribe',
        nodeCategory: 'external',
        outputs: () => ({
            event: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'On Message'),
            payload: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Payload'),
        }),
        validator: (node, flow) => {

            const errors: Record<string, string> = {};
            if (!node.controlValues.topic) {
                errors.topic = 'Topic is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'event')) {
                errors.event = 'Event is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'payload')) {
                errors.payload = 'Payload is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    numCast: {
        cfg: () => ({ controlValues: {}, id: getUID(), kind: 'numCast' }),
        controls: (_cfg, _node) => {
            return {};
        },
        inputs: () => ({ input: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Input') }),
        kindLabel: 'Number Value Cast',
        nodeCategory: 'number',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('boolean'), 'Output') }),
        validator: () => true,
    },
    numClamp: {
        cfg: () => ({ controlValues: { max: 0, min: 0 }, id: getUID(), kind: 'numClamp' }),
        controls: (cfg, node) => ({
            max: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Max' }, node, value: cfg.controlValues.max }),
            min: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Min' }, node, value: cfg.controlValues.min }),
        }),
        inputs: () => ({ input: new ClassicPreset.Input(new UiFlowNodeSocket('number'), 'Input') }),
        kindLabel: 'Number clamp',
        nodeCategory: 'number',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('number'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input')) {
                errors.input = 'Input is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }
            if (node.controlValues.min && node.controlValues.max && node.controlValues.min > node.controlValues.max) {
                errors.min = 'Min must be less than or equal to Max';
                errors.max = 'Max must be greater than or equal to Min';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    number: {
        cfg: () => ({ controlValues: { value: 0 }, id: getUID(), kind: 'number' }),
        controls: (cfg, node) => ({ value: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Value' }, node, value: cfg.controlValues.value }) }),
        inputs: () => ({}),
        kindLabel: 'Number',
        nodeCategory: 'number',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('number'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (typeof node.controlValues.value !== 'number' || isNaN(node.controlValues.value)) {
                errors.value = 'Value must be a number';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    script: {
        cfg: () => ({ controlValues: { scriptId: '' }, id: getUID(), kind: 'script' }),
        controls: (cfg, node) => ({ scriptId: new UiFlowNodeControl({ cfg: { kind: 'scriptSelect' }, node, value: cfg.controlValues.scriptId }) }),
        inputs: () => ({ trigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Trigger') }),
        kindLabel: 'Script',
        nodeCategory: 'other',
        outputs: () => ({ next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next') }),
        validator: (node, flow) => {
            const entityData = accessor.entityAsType('appletDesignVersion');
            const validScriptIds = entityData?.config.scripts.map(s => s.id) || [];
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'trigger')) {
                errors.trigger = 'Input is required';
            }
            if (!node.controlValues.scriptId) {
                errors.scriptId = 'Script is required';
            }
            if (!validScriptIds.includes(node.controlValues.scriptId)) {
                errors.scriptId = 'Script is not a valid script';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    slice: {
        cfg: () => ({ id: getUID(), kind: 'slice' }),
        controls: () => ({}),
        inputs: () => ({
            end: new ClassicPreset.Input(new UiFlowNodeSocket('number'), 'End Index (optional)'),
            input: new ClassicPreset.Input(new UiFlowNodeSocket('string'), 'Input'),
            start: new ClassicPreset.Input(new UiFlowNodeSocket('number'), 'Start Index'),
        }),
        kindLabel: 'Text slice',
        nodeCategory: 'text',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input')) {
                errors.input = 'Input is required';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'start')) {
                errors.start = 'Start is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    sparkplugConsumer: {
        cfg: () => ({
            controlValues: {
                deviceId: '',
                groupId: '',
                metricExpressions: [],
                nodeId: '',
            },
            id: getUID(),
            kind: 'sparkplugConsumer',
        }),
        controls: (cfg, node) => ({
            groupId: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'Group ID' }, node, value: cfg.controlValues.groupId }),
            nodeId: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'Node ID' }, node, value: cfg.controlValues.nodeId }),
            deviceId: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'Device ID' }, node, value: cfg.controlValues.deviceId }),
            metricExpressions: new UiFlowNodeControl({
                cfg: {
                    kind: 'button',
                    label: 'Add Metric',
                    onClick: () => node.applyControlValue('metricExpressions', [ ...cfg.controlValues.metricExpressions, '' ]),
                },
                node,
                value: cfg.controlValues.metricExpressions,
            }),
        }),
        inputs: () => ({}),
        kindLabel: 'Sparkplug consumer',
        nodeCategory: 'external',
        outputs: (cfg, node) => {
            const outputs: ReturnType<NodeInitializer<'sparkplugConsumer'>['outputs']> = {
                event: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Updated'),
            };
            for (let i = 0; i < cfg.controlValues.metricExpressions.length; i++) {
                const control = new UiFlowNodeControl({
                    cfg: {
                        kind: 'sparkplugConsumer.metricExpression',
                        onDelete () {
                            removeNodeSocket({
                                newNodeCfg: {
                                    ...node.cfg,
                                    controlValues: {
                                        ...node.cfg.controlValues,
                                        metricExpressions: [
                                            ...node.cfg.controlValues.metricExpressions.slice(0, i),
                                            ...node.cfg.controlValues.metricExpressions.slice(i + 1),
                                        ],
                                    },
                                },
                                node,
                                port: 'outputs',
                                portSocketsTotal: cfg.controlValues.metricExpressions.length,
                                socketToRemove: {
                                    idx: i,
                                    nameFn: (idx: number): `output${number}` => `output${idx}`,
                                },
                            });
                        },
                    },
                    node,
                    value: cfg.controlValues.metricExpressions[i],
                });
                control.changeCb = value => {
                    node.cfg.controlValues.metricExpressions[i] = value;
                    node.cfg.controlValues.metricExpressions = [ ...node.cfg.controlValues.metricExpressions ];
                    node.applyControlValue('metricExpressions', node.cfg.controlValues.metricExpressions);
                };
                outputs[`output${i}`] = new UiFlowNodeOutput({
                    control: control as UiFlowNodeControl<unknown>,
                    socket: new UiFlowNodeSocket('varValue'),
                });
            }
            return outputs;
        },
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!node.controlValues.deviceId) {
                errors.deviceId = 'Device ID is required';
            }
            if (!node.controlValues.groupId) {
                errors.groupId = 'Group ID is required';
            }
            if (!node.controlValues.nodeId) {
                errors.nodeId = 'Node ID is required';
            }
            if (!node.controlValues.metricExpressions.length) {
                errors.metricExpressions = 'At least one metric expression is required';
            }
            let atLeastOneOutput = false;
            for (let i = 0; i < node.controlValues.metricExpressions.length; i++) {
                const metricExpr = node.controlValues.metricExpressions[i];
                if (!metricExpr) {
                    errors[`output${i}`] = 'Metric expression is required';
                } else {
                    try {
                        const parsedMetricExpr = getMetricExpr(metricExpr);
                        if (!validateSparkplugMetricExpr(parsedMetricExpr)) {
                            errors[`output${i}`] = 'Metric expression is invalid';
                        }
                        if (Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === `output${i}`)) {
                            atLeastOneOutput = true;
                        }
                    } catch {
                        errors[`output${i}`] = 'Metric expression is invalid';
                    }
                }
            }
            if (!atLeastOneOutput) {
                for (let i = 0; i < node.controlValues.metricExpressions.length; i++) {
                    errors[`output${i}`] = 'At least one output is required';
                }
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'event')) {
                errors.event = 'Updated is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    start: {
        canDelete: false,
        canResize: false,
        cfg: () => ({ id: getUID(), kind: 'start' }),
        controls: () => ({}),
        inputs: () => ({}),
        kindLabel: 'Start',
        nodeCategory: 'events',
        supressNodeCategory: true,
        outputs: () => ({ next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next') }),
        validator: () => true,
    },
    strCast: {
        cfg: () => ({ controlValues: {}, id: getUID(), kind: 'strCast' }),
        controls: (_cfg, _node) => {
            return {};
        },
        inputs: () => ({ input: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Input') }),
        kindLabel: 'String Value Cast',
        nodeCategory: 'text',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('boolean'), 'Output') }),
        validator: () => true,
    },
    text: {
        cfg: () => ({ controlValues: { value: '' }, id: getUID(), kind: 'text' }),
        controls: (cfg, node) => ({ value: new UiFlowNodeControl({ cfg: { kind: 'text', placeholder: 'Enter a text value' }, node, value: cfg.controlValues.value }) }),
        inputs: () => ({}),
        kindLabel: 'Text',
        nodeCategory: 'text',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    timer: {
        cfg: () => ({ controlValues: { dir: 'down', limit: 0 }, id: getUID(), kind: 'timer' }),
        controls: (cfg, node) => ({
            dir: new UiFlowNodeControl({
                cfg: {
                    items: [
                        { label: 'Stopwatch', value: 'up' },
                        { label: 'Timer', value: 'down' },
                    ],
                    kind: 'selection',
                    label: 'Mode',
                },
                node,
                value: cfg.controlValues.dir,
            }),
            limit: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Limit', min: 0 }, node, value: cfg.controlValues.limit }),
        }),
        inputs: () => ({
            start: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Start'),
            stop: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Stop'),
            reset: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Reset'),
            initialValue: new ClassicPreset.Input(new UiFlowNodeSocket('number'), 'Initial Value'),
        }),
        kindLabel: 'Timer',
        nodeCategory: 'timeAndDate',
        outputs: () => ({
            done: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Done'),
            tick: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Tick'),
            value: new ClassicPreset.Output(new UiFlowNodeSocket('number'), 'Value'),
        }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (node.controlValues.limit < 0) {
                errors.limit = 'Limit must be at least 0';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'start')) {
                errors.start = 'Start is required';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'initialValue')) {
                errors.initialValue = 'Initial value is required';
            }
            if (
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'done') &&
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'tick') &&
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'value')
            ) {
                errors.done = 'At least one output is required';
                errors.tick = 'At least one output is required';
                errors.value = 'At least one output is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    txtFormat: {
        cfg: () => ({ controlValues: { formatTemplate: '', inputs: 0 }, id: getUID(), kind: 'txtFormat' }),
        controls: (cfg, node) => ({
            formatTemplate: new UiFlowNodeControl({
                cfg: { kind: 'text', placeholder: 'Format' },
                index: 0,
                node,
                value: cfg.controlValues.formatTemplate,
            }),
            inputs: new UiFlowNodeControl({
                cfg: {
                    kind: 'button',
                    label: 'Add Input',
                    onClick: () => node.applyControlValue('inputs', cfg.controlValues.inputs + 1),
                },
                index: 1,
                node,
                value: cfg.controlValues.inputs,
            }),
        }),
        inputs: (cfg, node) => {
            const inputs: ReturnType<NodeInitializer<'txtFormat'>['inputs']> = {};
            for (let i = 0; i < cfg.controlValues.inputs; i++) {
                const control = new UiFlowNodeControl({
                    cfg: {
                        kind: 'deletableSocket',
                        label: `$${i}`,
                        onDelete () {
                            removeNodeSocket({
                                newNodeCfg: {
                                    ...node.cfg,
                                    controlValues: {
                                        ...node.cfg.controlValues,
                                        inputs: node.cfg.controlValues.inputs - 1,
                                    },
                                },
                                node,
                                port: 'inputs',
                                portSocketsTotal: cfg.controlValues.inputs,
                                socketToRemove: {
                                    idx: i,
                                    nameFn: (idx: number): `input${number}` => `input${idx}`,
                                },
                            });
                        },
                    },
                    node,
                    value: null,
                });
                inputs[`input${i}`] = new UiFlowNodeInput({
                    control: control as UiFlowNodeControl<unknown>,
                    socket: new UiFlowNodeSocket('string'),
                });
            }
            return inputs;
        },
        kindLabel: 'Text format',
        nodeCategory: 'text',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!node.controlValues.formatTemplate) {
                errors.formatTemplate = 'Format Template is required';
            }
            if (!node.controlValues.inputs) {
                errors.inputs = 'At least one input is required';
            }
            let atLeastOneInput = false;
            for (let i = 0; i < node.controlValues.inputs; i++) {
                if (Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === `input${i}`)) {
                    atLeastOneInput = true;
                }
            }
            if (!atLeastOneInput) {
                for (let i = 0; i < node.controlValues.inputs; i++) {
                    errors[`input${i}`] = 'At least one input is required';
                }
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    udpRead: {
        cfg: () => ({ controlValues: { encoding: 'utf8', hostInterface: '', port: 1024 }, id: getUID(), kind: 'udpRead' }),
        controls: (cfg, node) => ({
            encoding: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'Encoding', optional: true }, node, value: cfg.controlValues.encoding }),
            hostInterface: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'IP' }, node, value: cfg.controlValues.hostInterface }),
            port: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Port', min: 1024 }, node, value: cfg.controlValues.port }),
        }),
        inputs: () => ({}),
        kindLabel: 'UDP read',
        nodeCategory: 'external',
        outputs: () => ({
            event: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'On Received'),
            payload: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Payload'),
        }),
        validator: (node, flow) => {

            const errors: Record<string, string> = {};
            if (!node.controlValues.port) {
                errors.port = 'Port is required';
            }
            if (!node.controlValues.hostInterface) {
                errors.hostInterface = 'IP is required';
            }
            if (
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'event') &&
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'payload')
            ) {
                errors.event = 'At least one output is required';
                errors.payload = 'At least one output is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    udpSocket: {
        cfg: () => ({
            controlValues: {
                bindAddress: '0.0.0.0',
                bindPort: 1234,
                connectToAddress: '',
                connectToPort: 5678,
                encoding: 'utf8',
            },
            id: getUID(),
            kind: 'udpSocket',
        }),
        controls: (cfg, node) => ({
            bindAddress: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'Bind Address' }, node, value: cfg.controlValues.bindAddress }),
            bindPort: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Bind Port' }, node, value: cfg.controlValues.bindPort }),
            connectToAddress: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'Connect To Address', optional: true }, node, value: cfg.controlValues.connectToAddress }),
            connectToPort: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Connect To Port', optional: true }, node, value: cfg.controlValues.connectToPort }),
            encoding: new UiFlowNodeControl({
                cfg: {
                    items: [
                        { label: 'UTF-8', value: 'utf8' },
                        { label: 'Hex', value: 'hex' },
                        { label: 'Ascii', value: 'ascii' },
                    ],
                    kind: 'selection',
                    label: 'Encoding',
                },
                node,
                value: cfg.controlValues.encoding,
            }),
        }),
        inputs: () => ({
            inputTrigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Send Trigger'),
            sendPayload: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Send Payload'),
        }),
        kindLabel: 'UDP Socket',
        nodeCategory: 'external',
        outputs: () => ({
            onReceivedTrigger: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'On Received'),
            onSentTrigger: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'On Send'),
            receivePayload: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Received Payload'),
        }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!node.controlValues.bindAddress) {
                errors.bindAddress = 'Bind address is required';
            }
            if (!node.controlValues.bindPort) {
                errors.bindPort = 'Bind port is required';
            }
            const hasSendWired = Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'inputTrigger');
            const hasReceievedWired = Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'onReceivedTrigger');
            if (!hasSendWired && !hasReceievedWired) {
                errors.inputTrigger = 'Upd socket must be configured to at least send or receive';
                errors.onReceivedTrigger = 'Upd socket must be configured to at least send or receive';
            }
            if (hasSendWired) {
                if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'sendPayload')) {
                    errors.sendPayload = 'Send payload is required when sending';
                }
                if (!node.controlValues.connectToAddress) {
                    errors.connectToAddress = 'Connect to address is required when sending';
                }
                if (!node.controlValues.connectToPort) {
                    errors.connectToPort = 'Connect to port is required when sending';
                }
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    udpWrite: {
        cfg: () => ({ controlValues: { host: '', port: 1024 }, id: getUID(), kind: 'udpWrite' }),
        controls: (cfg, node) => ({
            host: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'IP' }, node, value: cfg.controlValues.host }),
            port: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Port', min: 1024 }, node, value: cfg.controlValues.port }),
        }),
        inputs: () => ({
            trigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Trigger'),
            payload: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Payload'),
        }),
        kindLabel: 'UDP write',
        nodeCategory: 'external',
        outputs: () => ({ next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!node.controlValues.port) {
                errors.port = 'Port is required';
            }
            if (!node.controlValues.host) {
                errors.host = 'IP is required';
            }
            if (
                !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'trigger') &&
                !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'payload')
            ) {
                errors.trigger = 'At least one input is required';
                errors.payload = 'At least one input is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    varAssigned: {
        cfg: () => ({ controlValues: { changesOnly: false, varName: '' }, id: getUID(), kind: 'varAssigned' }),
        controls: (cfg, node) => ({
            changesOnly: new UiFlowNodeControl({ cfg: { kind: 'boolean', label: 'Changes Only' }, node, value: cfg.controlValues.changesOnly }),
            varName: new UiFlowNodeControl({ cfg: { kind: 'selectVar' }, node, value: cfg.controlValues.varName }),
        }),
        inputs: () => ({}),
        kindLabel: 'Variable assigned',
        nodeCategory: 'variable',
        outputs: () => ({
            trigger: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Trigger'),
            newValue: new ClassicPreset.Output(new UiFlowNodeSocket('varValue'), 'New Value'),
            oldValue: new ClassicPreset.Output(new UiFlowNodeSocket('varValue'), 'Old Value'),
        }),
        validator: (node, flow) => {
            const validVariables = accessor.appletDesign.availableVars();
            const errors: Record<string, string> = {};
            if (!node.controlValues.varName) {
                errors.varName = 'Variable is required';
            }
            if (!validVariables.includes(node.controlValues.varName)) {
                errors.varName = 'Variable is not a valid variable';
            }
            if (
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'trigger') &&
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'newValue') &&
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'oldValue')
            ) {
                errors.trigger = 'At least one output is required';
                errors.newValue = 'At least one output is required';
                errors.oldValue = 'At least one output is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    varRead: {
        cfg: () => ({ controlValues: { varName: '' }, id: getUID(), kind: 'varRead' }),
        controls: (cfg, node) => ({ varName: new UiFlowNodeControl({ cfg: { kind: 'selectVar' }, node, value: cfg.controlValues.varName }) }),
        inputs: () => ({}),
        kindLabel: 'Variable read',
        nodeCategory: 'variable',
        outputs: () => ({ value: new ClassicPreset.Output(new UiFlowNodeSocket('varValue'), 'Value') }),
        validator: (node, flow) => {
            const validVariables = accessor.appletDesign.availableVars();
            const errors: Record<string, string> = {};
            if (!node.controlValues.varName) {
                errors.varName = 'Variable is required';
            }
            if (!validVariables.includes(node.controlValues.varName)) {
                errors.varName = 'Variable is not a valid variable';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'value')) {
                errors.value = 'Value is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    varWrite: {
        cfg: () => ({ controlValues: { varName: '' }, id: getUID(), kind: 'varWrite' }),
        controls: (cfg, node) => ({
            varName: new UiFlowNodeControl({ cfg: { kind: 'selectVar' }, node, value: cfg.controlValues.varName }),
        }),
        inputs: () => ({
            trigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Trigger'),
            value: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Value'),
        }),
        kindLabel: 'Variable write',
        nodeCategory: 'variable',
        outputs: () => ({ next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next') }),
        validator: (node, flow) => {
            const validLocalVars = accessor.entityAsType('appletDesignVersion')?.config.varProviders[0].outputs || [];
            const errors: Record<string, string> = {};
            if (!node.controlValues.varName) {
                errors.varName = 'Variable is required';
            }
            if (!validLocalVars.includes(node.controlValues.varName)) {
                errors.varName = 'Variable is not a valid variable';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'trigger')) {
                errors.trigger = 'Trigger is required';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'value')) {
                errors.value = 'Value is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
};
