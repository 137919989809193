/* eslint-disable complexity */
import { camelToTitle } from '@redviking/argonaut-util/src/mes/appletUtil';
import { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
import { SaveResult } from 'types';
import { screensTabRoute } from '../util/consts';
import { accessor } from '@redviking/argonaut-core-ui/src/store';

const invalidGTZeroError = (item: string, screenName: string, aspectRatio: string): SaveResult => ({
    status: 'error',
    errorMessage: ` must be greater than 0 in screen ${screenName}:${camelToTitle(aspectRatio)}`,
    route: {
        name: screensTabRoute,
    },
});

const invalidVarSourceError = (item: string, screenName: string, aspectRatio: string): SaveResult => ({
    status: 'error',
    errorMessage: ` must be linked to a variable if using variable input in screen ${screenName}:${camelToTitle(aspectRatio)}`,
    route: {
        name: screensTabRoute,
    },
});

export const genericScreenDesignerCellValidations = (payload: {
    screen: Latest.Screen.Config;
    aspectRatio: Latest.Screen.Designer.GridLayoutAspectRatio;
    cellItem: Latest.Screen.Designer.LinkedGridCell | Latest.Screen.Designer.GridLayout['gridConfig'];
}): SaveResult | null => {
    const entityData = accessor.entityAsType('appletDesignVersion');
    const appletConfig = entityData?.config;
    if (!appletConfig) {
        return {
            status: 'error',
            errorMessage: 'Applet config not found',
            route: {
                name: screensTabRoute,
            },
        };
    }
    if ('rows' in payload.cellItem && 'columns' in payload.cellItem) {
        if (payload.cellItem.columns.find(c => c.weight <= 0) || payload.cellItem.rows.find(r => r.weight <= 0)) {
            accessor.appletDesign.patchAppletDesignState({
                activeScreenId: payload.screen.id,
            });
            return invalidGTZeroError('Row and column weights', payload.screen.name, payload.aspectRatio);
        }
    }
    if (payload.cellItem.elevation < 0) {
        return invalidGTZeroError('Elevation', payload.screen.name, payload.aspectRatio);
    }
    if (payload.cellItem.borderRadius < 0) {
        return invalidGTZeroError('Border radius', payload.screen.name, payload.aspectRatio);
    }
    if (payload.cellItem.margin.top < 0 || payload.cellItem.margin.right < 0 || payload.cellItem.margin.bottom < 0 || payload.cellItem.margin.left < 0) {
        return invalidGTZeroError('Margin', payload.screen.name, payload.aspectRatio);
    }
    if (payload.cellItem.padding.top < 0 || payload.cellItem.padding.right < 0 || payload.cellItem.padding.bottom < 0 || payload.cellItem.padding.left < 0) {
        return invalidGTZeroError('Padding', payload.screen.name, payload.aspectRatio);
    }
    if (payload.cellItem.border.top.width < 0 || payload.cellItem.border.top.width < 0 || payload.cellItem.border.top.width < 0 || payload.cellItem.border.top.width < 0) {
        return invalidGTZeroError('Border width', payload.screen.name, payload.aspectRatio);
    }

    const backgroundColorVarOrConst = payload.cellItem.backgroundColor;
    if (backgroundColorVarOrConst.type === 'var') {
        if (!backgroundColorVarOrConst.var) {
            return invalidVarSourceError('Background color', payload.screen.name, payload.aspectRatio);
        }
        /**
         * FUTURE: Once variable providers declare what the type of their outputs are we can use that to validate that the variable controlling background color is a string and not a number
         * NOTE: Currently returns a string representing the variable name. Sould return var config which contains the type
         */
        const linkedVariable = appletConfig.varProviders.find(vp => vp.outputs.includes(backgroundColorVarOrConst.var))?.outputs.find(o => o === backgroundColorVarOrConst.var);
        if (!linkedVariable) {
            return invalidVarSourceError('Background color', payload.screen.name, payload.aspectRatio);
        }
    }

    if ('type' in payload.cellItem) {
        if (payload.cellItem.type === 'infoBox') {
            const typedCellItem = payload.cellItem as Latest.Screen.Designer.InfoBoxCell;
            if (typedCellItem.additionalCellProperties.contentFontSize < 0) {
                return invalidGTZeroError('Content font size', payload.screen.name, payload.aspectRatio);
            }
            if (typedCellItem.additionalCellProperties.headerFontSize < 0) {
                return invalidGTZeroError('Header font size', payload.screen.name, payload.aspectRatio);
            }
            const contentVarOrConst = typedCellItem.additionalCellProperties.contentSourceVar;
            if (contentVarOrConst.type === 'var') {
                if (!contentVarOrConst.var) {
                    return invalidVarSourceError('Infobox Content', payload.screen.name, payload.aspectRatio);
                }
            }
            const headerVarOrConst = typedCellItem.additionalCellProperties.headerSourceVar;
            if (headerVarOrConst.type === 'var') {
                if (!headerVarOrConst.var) {
                    return invalidVarSourceError('Infobox Header', payload.screen.name, payload.aspectRatio);
                }
            }
        }
        if (payload.cellItem.type === 'tabGroup') {
            const typedCellItem = payload.cellItem as Latest.Screen.Designer.TabGroupCell;
            if (typedCellItem.additionalCellProperties.tabSequenceController) {
                const linkedVariableProvider = appletConfig.varProviders.find(vp => vp.outputs.includes(typedCellItem.additionalCellProperties.tabSequenceController));
                if (!linkedVariableProvider || linkedVariableProvider.type !== 'tabGroupController') {
                    return {
                        status: 'error',
                        errorMessage: `Tab sequence controller must be linked to a tab group controller variable in screen ${payload.screen.name}:${camelToTitle(payload.aspectRatio)}`,
                        route: {
                            name: screensTabRoute,
                        },
                    };
                }
            }
        }
        if (payload.cellItem.type === 'tabInstance') {
            const typedCellItem = payload.cellItem as Latest.Screen.Designer.TabInstanceCell;
            if (!typedCellItem.additionalCellProperties.label) {
                return {
                    status: 'error',
                    errorMessage: `Tab instance must have a label in screen ${payload.screen.name}:${camelToTitle(payload.aspectRatio)}`,
                    route: {
                        name: screensTabRoute,
                    },
                };
            }
        }
    }

    return null;
};
