/* eslint-disable max-lines */
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import { AllCellsSharedStyle, generateGridWrapperStyling, generateInfoBoxCellStyle, generateRegularCellStyle, generateSubGridStyle, generateTabGroupStyle } from '@redviking/argonaut-util/src/mes/screenDesignerUtil';
import { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
import { computed, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { toWritableComputedProps } from 'src/util/composables/writable-computed-prop';
import { CSSProperties } from 'vue/types/jsx';
import { useLocalStorage } from '@vueuse/core';

const colors: string[] = [
    '#f7e682', // yellow
    '#e396de', // purple
    '#86c8f4', // blue
];

// NOTE: This object gets saved in browser local storage. If this changes you will need to reinitialize the object in the local storage
export type ScreenInspectorState = {
    showMarchingAnts: boolean;
    layoutColumnIsOpen: boolean;
    cellHeirarchyIsOpen: boolean;
    addRemoveRowsIsOpen: boolean;
    cellStyleColumnIsOpen: boolean;
    addRemoveColumnsIsOpen: boolean;
    propertiesColumnIsOpen: boolean;
    cellConfigColumnIsOpen: boolean;
    quickActionsColumnIsOpen: boolean;
    overrideAspectRatio: Latest.Screen.Designer.GridLayoutAspectRatio | null;
    showSearchOnQuickActionMenu: boolean;
    designerControlsColumnIsOpen: boolean;
    stylingColumnsOpen: {
        margin: boolean;
        padding: boolean;
        borderColor: boolean;
        borderStyle: boolean;
        borderWidth: boolean;
    }
    useStyling: {
        margin: boolean;
        padding: boolean;
        elevation: boolean;
        borderColor: boolean;
        borderStyle: boolean;
        borderWidth: boolean;
        borderRadius: boolean;
        backgroundColor: boolean;
    };
}

export const useScreenDesignerData = () => {

    const initialScreenInspectorState: ScreenInspectorState = {
        showMarchingAnts: true,
        layoutColumnIsOpen: true,
        overrideAspectRatio: null,
        addRemoveRowsIsOpen: true,
        cellHeirarchyIsOpen: true,
        cellStyleColumnIsOpen: true,
        cellConfigColumnIsOpen: true,
        addRemoveColumnsIsOpen: true,
        propertiesColumnIsOpen: true,
        quickActionsColumnIsOpen: true,
        showSearchOnQuickActionMenu: false,
        designerControlsColumnIsOpen: true,
        useStyling: {
            margin: true,
            padding: true,
            elevation: true,
            borderColor: true,
            borderStyle: true,
            borderWidth: true,
            borderRadius: true,
            backgroundColor: true,
        },
        stylingColumnsOpen: {
            margin: false,
            padding: false,
            borderColor: false,
            borderStyle: false,
            borderWidth: false,
        },
    };
    const screenInspectorState = useLocalStorage('screenInspectorState', initialScreenInspectorState);

    const screenDesignerViewType = computed({
        get: () => accessor.appletDesign.screenDesignerViewType,
        set: newViewType => accessor.appletDesign.setScreenDesignerViewType(newViewType),
    });

    const activeTabPerTabGroup = computed({
        get: () => accessor.appletDesign.activeTabPerTabGroup,
        set: activeTabPerTabGroup => accessor.appletDesign.setActiveTabInstanceForTabViewingRefMap(activeTabPerTabGroup),
    });

    const targetedGridId = computed({
        get: () => accessor.appletDesign.targetedGridId,
        set: newCellId => accessor.appletDesign.setTargetGrid(newCellId),
    });

    const currentCellSelection = computed({
        get: () => accessor.appletDesign.currentCellSelection,
        set: newCellId => accessor.appletDesign.setSelection(newCellId),
    });

    const aspectRatio = computed({
        get: () => accessor.appletDesign.activeScreenLayout,
        set: newAspectRatio => accessor.appletDesign.setActiveScreenLayout(newAspectRatio),
    });

    const gridLayout = computed({
        get: () => {
            const screens = accessor.entityAsType('appletDesignVersion')!.config.screens;
            return (screens.find(s => s.id === accessor.appletDesign.activeScreenId) || screens[0])!.gridLayoutAspectRatios[accessor.appletDesign.activeScreenLayout];
        },
        set: newGridLayout => accessor.appletDesign.setScreenCfg({
            ...accessor.entityAsType('appletDesignVersion')!.config.screens.find(s => s.id === accessor.appletDesign.activeScreenId) || accessor.entityAsType('appletDesignVersion')!.config.screens[0],
            gridLayoutAspectRatios: {
                ...accessor.entityAsType('appletDesignVersion')!.config.screens.find(s => s.id === accessor.appletDesign.activeScreenId)!.gridLayoutAspectRatios,
                [accessor.appletDesign.activeScreenLayout]: newGridLayout,
            },
        }),
    });

    const hoveredData = computed({
        get: () => accessor.appletDesign.hoveredData,
        set: newHoveredData => accessor.appletDesign.setHoveredData(newHoveredData),
    });

    const dragData = computed({
        get: () => accessor.appletDesign.dragData,
        set: newDragData => accessor.appletDesign.setDragData(newDragData),
    });

    const activeScreenCellMap = computed<Record<string, { idx: number, cell: Latest.Screen.Designer.LinkedGridCell }>>(() => {
        const ret = gridLayout.value.linkedGridCells.reduce((acc, cell, idx) => {
            acc[cell.id] = { idx, cell };
            return acc;
        }, {} as Record<string, { idx: number, cell: Latest.Screen.Designer.LinkedGridCell }>);
        return ret;
    });

    const screenInspectorDimensions = computed({
        get: () => accessor.appletDesign.screenDesignerInspectorDimensions,
        set: newDimensions => accessor.appletDesign.setScreenDesignerInspectorDimensions(newDimensions),
    });

    return {
        dragData,
        gridLayout,
        aspectRatio,
        hoveredData,
        targetedGridId,
        activeScreenCellMap,
        screenInspectorState,
        activeTabPerTabGroup,
        currentCellSelection,
        screenDesignerViewType,
        screenInspectorDimensions,
    };
};

const {
    dragData,
    gridLayout,
    aspectRatio,
    targetedGridId,
    activeTabPerTabGroup,
    screenInspectorState,
    currentCellSelection,
    screenDesignerViewType,
} = useScreenDesignerData();
const {
    gridConfig,
    linkedGridCells,
} = toWritableComputedProps(gridLayout);
const {
    useStyling,
    overrideAspectRatio,
} = toWritableComputedProps(screenInspectorState);
const {
    rootCellId,
    includedCellIds,
} = toWritableComputedProps(currentCellSelection);

const getCellColorUsingGridLayer = (initialLgc: Latest.Screen.Designer.LinkedGridCell): string => {
    const rootGridCellId = gridLayout.value.gridConfig.id;

    const colorHelper = (currentLgc: Latest.Screen.Designer.LinkedGridCell, currentLayer: number): number => {
        if (currentLgc.parentGridId === rootGridCellId) {
            return currentLayer;
        }
        const parentLgc = gridLayout.value.linkedGridCells.find(parentLgc => parentLgc.id === currentLgc.parentGridId);
        if (!parentLgc) {
            throw new Error('Parent grid cell not found');
        }
        // keep going up the tree until we hit the root grid cell. If the parent cell is a tab group then dont count it as a layer since it is just a container for the tab instance
        return colorHelper(parentLgc, parentLgc.type === 'tabGroup' ? currentLayer : ++currentLayer);
    };
    const numLayers = colorHelper(initialLgc, 0);
    const colorHex = colors[numLayers % 3];
    return colorHex;
};

const filterOutStylingFromLocalStorage = (borderCfg: Latest.Screen.Designer.LinkedGridCell['border'], cellStyle: AllCellsSharedStyle) => {
    const filteredCellStyle: AllCellsSharedStyle = {
        ...cellStyle,
        position: cellStyle.position,
        fontFamily: cellStyle.fontFamily,
        paddingTop: useStyling.value.padding ? cellStyle.paddingTop : '0px',
        inset: useStyling.value.margin ? cellStyle.inset : '0px 0px 0px 0px',
        paddingLeft: useStyling.value.padding ? cellStyle.paddingLeft : '0px',
        paddingRight: useStyling.value.padding ? cellStyle.paddingRight : '0px',
        paddingBottom: useStyling.value.padding ? cellStyle.paddingBottom : '0px',
        borderRadius: useStyling.value.borderRadius ? cellStyle.borderRadius : '0px',
        backgroundColor: useStyling.value.backgroundColor ? cellStyle.backgroundColor : 'transparent',
        borderTop: `${useStyling.value.borderWidth ? borderCfg.top.width : 2}px ${useStyling.value.borderStyle ? borderCfg.top.style : 'solid'} ${useStyling.value.borderColor ? borderCfg.top.color : 'black'}`,
        borderLeft: `${useStyling.value.borderWidth ? borderCfg.left.width : 2}px ${useStyling.value.borderStyle ? borderCfg.left.style : 'solid'} ${useStyling.value.borderColor ? borderCfg.left.color : 'black'}`,
        borderRight: `${useStyling.value.borderWidth ? borderCfg.right.width : 2}px ${useStyling.value.borderStyle ? borderCfg.right.style : 'solid'} ${useStyling.value.borderColor ? borderCfg.right.color : 'black'}`,
        borderBottom: `${useStyling.value.borderWidth ? borderCfg.bottom.width : 2}px ${useStyling.value.borderStyle ? borderCfg.bottom.style : 'solid'} ${useStyling.value.borderColor ? borderCfg.bottom.color : 'black'}`,
    };
    return filteredCellStyle;
};

export const generateSubGridContainerStylingForEditing = (subGrid: Latest.Screen.Designer.SubGridCell | Latest.Screen.Designer.TabInstanceCell, fontFamily: string) => {
    return filterOutStylingFromLocalStorage(subGrid.border, generateSubGridStyle(
        subGrid,
        subGrid.backgroundColor.type === 'const' ? subGrid.backgroundColor.val.val : '#00000000',
        gridLayout.value.linkedGridCells.filter(lgc => lgc.parentGridId === subGrid.id),
        fontFamily
    ));
};

export const generateTabGroupContainerStylingForEditing = (tabGroup: Latest.Screen.Designer.TabGroupCell, fontFamily: string) => {
    const ret = generateTabGroupStyle(tabGroup, tabGroup.backgroundColor.type === 'const' ? tabGroup.backgroundColor.val.val : '#00000000', fontFamily);
    // setting the height of the header to be non-zero during editing (if it is configured to be hidden) so that the user can still navigate the tabs
    if (tabGroup.additionalCellProperties.tabHeaderSize === 'hidden' && screenDesignerViewType.value === 'wireframe') {
        ret.tabContentStyle = {
            height: '80%',
            backgroundColor: 'transparent',
        };
        ret.tabHeaderStyle = {
            height: '20%',
        };
    }
    ret.tabGroupStyle = filterOutStylingFromLocalStorage(tabGroup.border, ret.tabGroupStyle);
    return ret;
};

export const generateRegularCellStylingForEditing = (payload: {
    fontFamily: string;
    regularLinkedGridCellCfg: Latest.Screen.Designer.RegularGridCell;
}) => {
    let backgroundColor = payload.regularLinkedGridCellCfg.backgroundColor.type === 'const' ? payload.regularLinkedGridCellCfg.backgroundColor.val.val : '#00000000';
    let opacity = 1;
    if (screenDesignerViewType.value === 'wireframe') {
        backgroundColor = getCellColorUsingGridLayer(payload.regularLinkedGridCellCfg);
        if (targetedGridId.value !== gridConfig.value.id && targetedGridId.value !== payload.regularLinkedGridCellCfg.parentGridId) {
            opacity = 0.5;
        }
    }
    const ret = {
        opacity,
        ...filterOutStylingFromLocalStorage(payload.regularLinkedGridCellCfg.border, generateRegularCellStyle(payload.regularLinkedGridCellCfg, backgroundColor, payload.fontFamily)),
    };
    if (screenDesignerViewType.value === 'wireframe') {
        ret.borderTop = '1px solid black';
        ret.borderBottom = '1px solid black';
        ret.borderLeft = '1px solid black';
        ret.borderRight = '1px solid black';
    }
    return ret;
};

export const generateInfoBoxCellStylingForEditing = (payload: {
    fontFamily: string;
    infoBoxLinkedGridCellCfg: Latest.Screen.Designer.InfoBoxCell;
}) => {
    let opacity = 1;
    if (targetedGridId.value !== payload.infoBoxLinkedGridCellCfg.parentGridId) {
        opacity = 0.5;
    }
    let backgroundColor = payload.infoBoxLinkedGridCellCfg.backgroundColor.type === 'const' ? payload.infoBoxLinkedGridCellCfg.backgroundColor.val.val : '#00000000';
    if (screenDesignerViewType.value === 'wireframe') {
        backgroundColor = getCellColorUsingGridLayer(payload.infoBoxLinkedGridCellCfg);
    }
    const infoBoxStyle = generateInfoBoxCellStyle(payload.infoBoxLinkedGridCellCfg, backgroundColor, payload.fontFamily);
    const ret = {
        ...infoBoxStyle,
        general: {
            ...filterOutStylingFromLocalStorage(payload.infoBoxLinkedGridCellCfg.border, infoBoxStyle.general),
            opacity,
        },
    };
    if (screenDesignerViewType.value === 'wireframe') {
        ret.general.borderTop = '1px solid black';
        ret.general.borderBottom = '1px solid black';
        ret.general.borderLeft = '1px solid black';
        ret.general.borderRight = '1px solid black';
    }
    return ret;
};

export const generateGridContainerStylingForEditing = (fontFamily: string) => {
    return filterOutStylingFromLocalStorage(gridConfig.value.border, generateSubGridStyle(
        gridConfig.value,
        gridConfig.value.backgroundColor.type === 'const' ? gridConfig.value.backgroundColor.val.val : '#00000000',
        linkedGridCells.value.filter(lgc => lgc.parentGridId === gridConfig.value.id),
        fontFamily
    ));
};

export const removeSubGrid = (subGridId: string) => {
    targetedGridId.value = gridConfig.value.id;

    const removeSubGridHelper = (newLinkedGridCells: Latest.Screen.Designer.LinkedGridCell[], subGridId: string): Latest.Screen.Designer.LinkedGridCell[] => {
        const childCells: {
            regularIds: string[];
            nonRegularIds: string[];
        } = {
            regularIds: [],
            nonRegularIds: [],
        };

        for (const filteredLgc of newLinkedGridCells.filter(lgc => lgc.parentGridId === subGridId)) {
            if (filteredLgc.type === 'regular') {
                childCells.regularIds.push(filteredLgc.id);
            } else {
                childCells.nonRegularIds.push(filteredLgc.id);
            }
        }

        const subGridLgc = newLinkedGridCells.find((lgc): lgc is Latest.Screen.Designer.SubGridCell | Latest.Screen.Designer.TabInstanceCell | Latest.Screen.Designer.TabGroupCell => lgc.id === subGridId && lgc.type !== 'regular');
        if (!subGridLgc) {
            throw new Error('Sub grid not found when removing sub grid');
        }
        if (subGridLgc.type === 'tabGroup') {
            const newTabViewingRefMap = activeTabPerTabGroup.value;
            delete newTabViewingRefMap[subGridLgc.id];
            activeTabPerTabGroup.value = newTabViewingRefMap;
        } else if (subGridLgc.type === 'tabInstance' && activeTabPerTabGroup.value[subGridLgc.parentGridId] === subGridLgc.id) {
            const firstTabInTabGroup = newLinkedGridCells.find((lgc): lgc is Latest.Screen.Designer.TabInstanceCell => lgc.type === 'tabInstance' && lgc.parentGridId === subGridLgc.parentGridId && lgc.idx === 0);
            if (!firstTabInTabGroup) {
                throw new Error('First Tab in Tab Group not found when removing sub grid');
            }
            // if the first tab is being deleted and it is the current active tab for a tab group set the active tab to be the second tab. There will always be a second tab if the first tab is being deleted. If not is handled elsewhere
            if (firstTabInTabGroup.id === subGridLgc.id) {
                const secondTabInTabGroup = newLinkedGridCells.find((lgc): lgc is Latest.Screen.Designer.TabInstanceCell => lgc.type === 'tabInstance' && lgc.parentGridId === subGridLgc.parentGridId && lgc.idx === 1);
                if (!secondTabInTabGroup) {
                    throw new Error('Second Tab in Tab Group not found when removing sub grid');
                }
                activeTabPerTabGroup.value = {
                    ...activeTabPerTabGroup.value,
                    [subGridLgc.parentGridId]: secondTabInTabGroup.id,
                };
            } else {
                activeTabPerTabGroup.value = {
                    ...activeTabPerTabGroup.value,
                    [subGridLgc.parentGridId]: firstTabInTabGroup.id,
                };
            }
        }
        let filteredLinkedGridCells = newLinkedGridCells.filter(lgc => lgc.id !== subGridId && !childCells.regularIds.includes(lgc.id));

        for (const childSubGridId of childCells.nonRegularIds) {
            filteredLinkedGridCells = removeSubGridHelper(filteredLinkedGridCells, childSubGridId);
        }
        return filteredLinkedGridCells;
    };

    const newLinkedGridCells = removeSubGridHelper(linkedGridCells.value, subGridId);
    linkedGridCells.value = newLinkedGridCells;
};

export const removeRowOrColumnLinkedGridCells = (type: keyof Latest.Screen.Designer.LinkedGridCellCoordinate, itemNumber: number) => {
    const cellIdsToUpdate: string[] = [];
    const nonSubGridIdsToDelete: string[] = [];
    const subGridIdsToDelete: string[] = [];
    linkedGridCells.value.forEach(lgc => {
        if (lgc.parentGridId === targetedGridId.value) {
            // todo going to need a better way to determine if a cell has children or not. once more cell types gets added this is going to be an issue
            if (lgc.type === 'regular' || lgc.type === 'infoBox') {
                if ((lgc.coordinates.length === 1 && lgc.coordinates[0][type] === itemNumber) || lgc.coordinates.filter(c => c[type] === itemNumber).length === lgc.coordinates.length) {
                    nonSubGridIdsToDelete.push(lgc.id);
                } else if (lgc.coordinates.find(c => c[type] >= itemNumber)) {
                    cellIdsToUpdate.push(lgc.id);
                }
            } else if (lgc.type === 'subGrid' || lgc.type === 'tabGroup' || lgc.type === 'tabInstance') {
                if ((lgc.coordinates.length === 1 && lgc.coordinates[0][type] === itemNumber) || lgc.coordinates.filter(c => c[type] === itemNumber).length === lgc.coordinates.length) {
                    subGridIdsToDelete.push(lgc.id);
                } else if (lgc.coordinates.find(c => c[type] >= itemNumber)) {
                    cellIdsToUpdate.push(lgc.id);
                }
            }
        }
    });

    if (rootCellId.value && nonSubGridIdsToDelete.includes(rootCellId.value)) {
        rootCellId.value = null;
        includedCellIds.value = [];
    }

    subGridIdsToDelete.forEach(subGridId => {
        removeSubGrid(subGridId);
    });
    linkedGridCells.value = linkedGridCells.value.filter(lgc => !nonSubGridIdsToDelete.includes(lgc.id));
    linkedGridCells.value = linkedGridCells.value.map(lgc => {
        if (cellIdsToUpdate.includes(lgc.id)) {
            return {
                ...lgc,
                coordinates: lgc.coordinates.filter(coord => {
                    return coord[type] !== itemNumber;
                }).map(coord => {
                    if (coord[type] > itemNumber) {
                        return {
                            ...coord,
                            [type]: coord[type] - 1,
                        };
                    }
                    return coord;
                }),
            };
        }
        return lgc;
    });
};

export const generateChildCellsForRowsAndColumns = (payload: {
    numRows: number;
    numCols: number;
    parentId: string;
}) => {
    const lgcCopy = [ ...linkedGridCells.value ];
    for (let rIdx = 0; rIdx < payload.numRows; rIdx++) {
        for (let cIdx = 0; cIdx < payload.numCols; cIdx++) {
            const doesChildCellExist = lgcCopy.find(childCell => childCell.coordinates.find(coord => coord.row === rIdx && coord.column === cIdx) && childCell.parentGridId === payload.parentId);
            if (!doesChildCellExist) {
                lgcCopy.push(generateDefaultRegularGridCellConfig({
                    row: rIdx,
                    column: cIdx,
                    parentGridId: payload.parentId,
                }));
            }
        }
    }
    linkedGridCells.value = lgcCopy;
};

export const useDoubleClicker = <T extends Record<string, unknown>>(payload: {
    threshold: number;
    singleClickHandler?: (event: MouseEvent, additionalData?: T) => void;
    doubleClickHandler?: (event: MouseEvent, additionalData?: T) => void;
}): (event: MouseEvent, additionalData?: T) => void => {
    const clickTimer = ref<ReturnType<typeof setTimeout> | null>(null);
    return (event: MouseEvent, additionalData?: T) => {
        if (clickTimer.value) {
            clearTimeout(clickTimer.value);
            clickTimer.value = null;
            if (payload.doubleClickHandler) {
                payload.doubleClickHandler(event, additionalData);
            }
        } else {
            clickTimer.value = setTimeout(() => {
                if (payload.singleClickHandler) {
                    payload.singleClickHandler(event, additionalData);
                }
                clickTimer.value = null;
            }, payload.threshold);
        }
    };
};

export const useCustomDoubleClicker = <T>(payload: {
    threshold: number;
    singleClickHandler?: (clickPayload: T) => void;
    doubleClickHandler?: (clickPayload: T) => void;
}): (clickPayload: T) => void => {
    const clickTimer = ref<ReturnType<typeof setTimeout> | null>(null);
    return (clickPayload: T) => {
        if (clickTimer.value) {
            clearTimeout(clickTimer.value);
            clickTimer.value = null;
            if (payload.doubleClickHandler) {
                payload.doubleClickHandler(clickPayload);
            }
        } else {
            clickTimer.value = setTimeout(() => {
                if (payload.singleClickHandler) {
                    payload.singleClickHandler(clickPayload);
                }
                clickTimer.value = null;
            }, payload.threshold);
        }
    };
};

const generateDefaultRegularGridCellConfig = (payload: {
    row: number;
    column: number;
    parentGridId: string;
}): Latest.Screen.Designer.RegularGridCell => ({
    id: uuidv4(),
    elevation: 0,
    type: 'regular',
    borderRadius: 0,
    name: 'Regular Cell',
    parentGridId: payload.parentGridId,
    margin: { top: 0, right: 0, bottom: 0, left: 0 },
    padding: { top: 0, right: 0, bottom: 0, left: 0 },
    coordinates: [ { row: payload.row, column: payload.column } ],
    backgroundColor: {
        type: 'const',
        val: {
            type: 'str',
            status: 'ok',
            val: '#00000000',
        },
    },
    border: {
        top: { color: 'black', style: 'none', width: 1 },
        left: { color: 'black', style: 'none', width: 1 },
        right: { color: 'black', style: 'none', width: 1 },
        bottom: { color: 'black', style: 'none', width: 1 },
    },
    additionalCellProperties: {
        argoCellId: '',
    },
});

export const addTab = (tabGroupId: string, opts?:{
    index?: number,
    label?: string,
}) => {
    const currentNumTabs = linkedGridCells.value.filter(lgc => lgc.parentGridId === tabGroupId).length;

    const newSubGridCell: Latest.Screen.Designer.TabInstanceCell = {
        elevation: 0,
        id: uuidv4(),
        borderRadius: 0,
        type: 'tabInstance',
        name: 'Tab Instance Cell',
        idx: opts?.index ?? currentNumTabs,
        parentGridId: tabGroupId,
        coordinates: [ { row: 0, column: 0 } ],
        rows: [ { weight: 1 }, { weight: 1 } ],
        columns: [ { weight: 1 }, { weight: 1 } ],
        margin: { top: 0, right: 0, bottom: 0, left: 0 },
        padding: { top: 0, right: 0, bottom: 0, left: 0 },
        backgroundColor: {
            type: 'const',
            val: {
                type: 'str',
                status: 'ok',
                val: '#00000000',
            },
        },
        border: {
            top: { width: 0, style: 'none', color: '#00000000' },
            left: { width: 0, style: 'none', color: '#00000000' },
            right: { width: 0, style: 'none', color: '#00000000' },
            bottom: { width: 0, style: 'none', color: '#00000000' },
        },
        additionalCellProperties: {
            label: opts?.label ?? `Tab ${currentNumTabs + 1}`,
        },
    };

    linkedGridCells.value = [
        ...linkedGridCells.value,
        newSubGridCell,
    ];

    generateChildCellsForRowsAndColumns({
        parentId: newSubGridCell.id,
        numRows: newSubGridCell.rows.length,
        numCols: newSubGridCell.columns.length,
    });

    activeTabPerTabGroup.value = {
        ...activeTabPerTabGroup.value,
        [tabGroupId]: newSubGridCell.id,
    };

    return newSubGridCell.id;
};

export const generateDefaultGridLayout = (enabled: boolean): Latest.Screen.Designer.GridLayout => {
    const rootGridId = uuidv4();
    return {
        enabled,
        gridConfig: {
            elevation: 0,
            id: rootGridId,
            borderRadius: 0,
            name: 'Base Layer',
            rows: [ { weight: 1 } ],
            columns: [ { weight: 1 } ],
            margin: { top: 0, right: 0, bottom: 0, left: 0 },
            padding: { top: 0, right: 0, bottom: 0, left: 0 },
            backgroundColor: {
                type: 'const',
                val: {
                    type: 'str',
                    status: 'ok',
                    val: '#00000000',
                },
            },
            border: {
                top: { color: 'black', style: 'none', width: 1 },
                left: { color: 'black', style: 'none', width: 1 },
                right: { color: 'black', style: 'none', width: 1 },
                bottom: { color: 'black', style: 'none', width: 1 },
            },
        },
        linkedGridCells: [
            {
                id: uuidv4(),
                elevation: 0,
                type: 'regular',
                borderRadius: 0,
                name: 'Regular Cell',
                parentGridId: rootGridId,
                coordinates: [ { row: 0, column: 0 } ],
                margin: { top: 0, right: 0, bottom: 0, left: 0 },
                padding: { top: 0, right: 0, bottom: 0, left: 0 },
                backgroundColor: {
                    type: 'const',
                    val: {
                        type: 'str',
                        status: 'ok',
                        val: '#00000000',
                    },
                },
                border: {
                    top: { color: 'black', style: 'none', width: 1 },
                    left: { color: 'black', style: 'none', width: 1 },
                    right: { color: 'black', style: 'none', width: 1 },
                    bottom: { color: 'black', style: 'none', width: 1 },
                },
                additionalCellProperties: {
                    argoCellId: '',
                },
            },
        ],
    };
};

/** This function generates the styling that is needed around the root of the grid i.e. position relative, aspect-ratio, etc */
export const generateGridWrapperStylingForEditing = (width: number, height: number): CSSProperties => {
    const ret: CSSProperties = {
        ...generateGridWrapperStyling(),
        position: 'relative',
    };
    let computedHeight = -1;
    switch (overrideAspectRatio.value || aspectRatio.value) {
        case 'mobile': {
            ret.aspectRatio = '9 / 16';
            computedHeight = width / (9 / 16);
            break;
        }
        case 'tablet': {
            ret.aspectRatio = '16 / 10';
            computedHeight = width / (16 / 10);
            break;
        }
        case 'largeDisplay': {
            ret.aspectRatio = '1.85 / 1';
            computedHeight = width / (1.85 / 1);
            break;
        }
        case 'desktop':
        default: {
            ret.aspectRatio = '16 / 9';
            computedHeight = width / (16 / 9);
            break;
        }
    }
    if (computedHeight > height) {
        ret.height = `${height}px`;
    } else {
        ret.width = `${width}px`;
    }
    return ret;
};

/**
 * There are a lot of ternary operations here but that is because there are many combinations of configuration that all do the exact same thing which is as follows:
 * When a drag event occurs, grab the row or column that is being dragged as well as it's adjacent row or column.
 * Then adjust the weight of the dragged row or column by either +0.1 or -0.1 depending on the direction of the scroll.
 * Apply the inverse of the weight change to the adjacent row or column and update the parent grid.
 */
export const applyDragChange = (payload: {
    clientX: number;
    clientY: number;
    type: 'grow' | 'shrink';
}) => {
    if (!dragData.value) {
        return;
    }
    let rowsAndColumnsFromParentGrid: Pick<Latest.Screen.Designer.SubGridCell, 'rows' | 'columns'>;

    if (dragData.value.gridId === gridConfig.value.id) {
        rowsAndColumnsFromParentGrid = {
            rows: gridConfig.value.rows,
            columns: gridConfig.value.columns,
        };
    } else {
        const parentSubGrid = linkedGridCells.value.find((lgc): lgc is Latest.Screen.Designer.SubGridCell | Latest.Screen.Designer.TabInstanceCell => lgc.id === dragData.value?.gridId && (lgc.type === 'subGrid' || lgc.type === 'tabInstance'));
        if (!parentSubGrid) {
            throw new Error('Parent sub grid not found for drag event');
        }
        rowsAndColumnsFromParentGrid = {
            rows: parentSubGrid.rows,
            columns: parentSubGrid.columns,
        };
    }

    const coordinateType: 'rows' | 'columns' = dragData.value.direction === 'up' || dragData.value.direction === 'down' ? 'rows' : 'columns';

    const mainItem = { ...rowsAndColumnsFromParentGrid[coordinateType][dragData.value.rowOrColNum] };
    const affectedItem = { ...rowsAndColumnsFromParentGrid[coordinateType][dragData.value.direction === 'left' || dragData.value.direction === 'up' ? dragData.value.rowOrColNum - 1 : dragData.value.rowOrColNum + 1] };
    mainItem.weight = Math.round((payload.type === 'grow' ? mainItem.weight + 0.1 : mainItem.weight - 0.1) * 10) / 10;
    affectedItem.weight = Math.round((payload.type === 'grow' ? affectedItem.weight - 0.1 : affectedItem.weight + 0.1) * 10) / 10;
    if (mainItem.weight < 0.1 || affectedItem.weight < 0.1) {
        return;
    }
    rowsAndColumnsFromParentGrid[coordinateType] = dragData.value.direction === 'left' || dragData.value.direction === 'up'
        ? [
            ...rowsAndColumnsFromParentGrid[coordinateType].slice(0, dragData.value.rowOrColNum - 1),
            affectedItem,
            mainItem,
            ...rowsAndColumnsFromParentGrid[coordinateType].slice(dragData.value.rowOrColNum + 1),
        ]
        : [
            ...rowsAndColumnsFromParentGrid[coordinateType].slice(0, dragData.value.rowOrColNum),
            mainItem,
            affectedItem,
            ...rowsAndColumnsFromParentGrid[coordinateType].slice(dragData.value.rowOrColNum + 2),
        ];

    if (dragData.value.gridId === gridConfig.value.id) {
        gridConfig.value = {
            ...gridConfig.value,
            ...rowsAndColumnsFromParentGrid,
        };
    } else {
        linkedGridCells.value = linkedGridCells.value.map(lgc => {
            if (lgc.id === dragData.value?.gridId && (lgc.type === 'subGrid' || lgc.type === 'tabInstance')) {
                const ret = {
                    ...lgc,
                    ...rowsAndColumnsFromParentGrid,
                };
                return ret;
            }
            return lgc;
        });
    }

    dragData.value = {
        ...dragData.value,
        pos: {
            x: payload.clientX,
            y: payload.clientY,
        },
    };
};
