/* eslint-disable max-lines */
import type { EntityDetailMode } from 'src/components/EntityDetail/mode';
import { DurationFormat, TimeFormat, formatDuration, formatTime } from '@redviking/applet-runtime/src/providers/FormattedProvider';
import { messages as createMaterialI18nMessages } from 'src/applet/design/varps/create-material/create-material.mes.i18n';
import { messages as sparkplugDeviceWriteMessages } from 'src/applet/design/varps/sparkplugDeviceWrite/sparkplug-device-write.mes.i18n';
import { messages as mqttPublishMessages } from 'src/applet/design/varps/mqtt-publish/mqtt-publish.mes.i18n';
import { messages as udpSocketMessages } from 'src/applet/design/varps/udp-socket/udp-socket.mes.i18n';
import { messages as mqttSubscribeMessages } from 'src/applet/design/varps/mqtt-subscribe/mqtt-subscribe.mes.i18n';
import { messages as timerMessages } from 'src/applet/design/varps/timer/timer.mes.i18n';
import { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';

const varType: {
    [t in Latest.OkAppletVarType]: string;
} = {
    bool: 'Boolean',
    null: 'Empty',
    num: 'Number',
    str: 'Text',
};

const varStatus: {
    [s in Latest.AppletVar['status']]: string;
} = {
    error: 'Error',
    ok: 'Ok',
    pending: 'Pending',
};

export const messages = {
    auth: {
        screen: {
            description: 'Require Operator authentication in order to interact with this screen',
            title: 'Require Operator Login',
        },
    },
    cells: {
        'attrLookup': {
            cond: {
                assigned: {
                    description: 'Checks if an attribute is assigned to a material',
                    text: 'Assignment',
                },
                nameMatch: {
                    description: 'Checks if the name of an attribute matches a search pattern',
                    text: 'Attribute Name Comparison',
                },
                propMatchNum: {
                    description: 'Compares the value of a numeric property to a constant',
                    text: 'Numeric Property Comparison (const)',
                },
                propMatchStr: {
                    description: 'Compares the value of a text property to a search pattern',
                    text: 'String Property Comparison',
                },
            },
            description: 'Display a table of attributes that satisfy conditions',
            error: {
                attrSchema: 'Attribute schema required',
                cols: 'No selected display columns',
                materialId: 'A variable for providing the material ID is required',
                missingVar: (varName: string) => `Cannot find referenced variable "${varName}" for condition operand`,
                propId: 'Comparisons against an attribute property requires a property to be selected',
                varNotSet: 'Variable selection is required for a condition with a variable source',
            },
            inline: {
                assignedToMaterial: 'That are assigned to the material with this ID',
                displayCols: 'Then, display these attribute properties as columns:',
                include: 'Include attributes from the schema:',
                satisfy: 'and satisfy all of the following conditions:',
                searchPattern: 'Separate multiple patterns with commas, use * for wildcards',
            },
            label: {
                constOpNum: 'Compare to value',
                constOpStr: 'Search pattern',
                var: 'Material ID variable',
            },
            prompt: {
                addCond: 'Add Condition',
                schema: 'Select an attribute schema',
            },
            title: 'Attribute Lookup Table',
        },
        'btn': {
            description: 'When pressed, a Button Cell can be configured to set a variable to a constant value, or to a timestamp of the moment the button was pressed, or to execute a script',
            labels: {
                // captureFocus: 'Automatically capture key strokes',
                color: 'Color',
                constantVal: 'Input a constant value',
                elevation: 'Button Elevation',
                enabled: 'Enabled',
                eventName: 'Applet Event Name',
                label: 'Button Label',
                size: 'Size',
                targetScreen: 'Select a target Screen',
                targetVar: 'Select a variable',
                varBehavior: 'What should this button do when pressed?',
            },
            title: 'Button',
        },
        'circularProgress': {
            condition: {
                boolOperation: 'The progress bar should flash red when {boolSelection} of the following comparisons are satisfied.',
            },
            description: 'Display a circular progress bar',
        },
        'datetime': {
            description: 'Display the current date and/or time',
            title: 'Current Date / Time',
        },
        'input': {
            clearCondition: {
                boolOperation: 'When {boolSelection} of the following comparisons are satisfied, clear the input:',
                toggle: (current: boolean) => current
                    ? 'Remove Clear Condition'
                    : 'Add Clear Condition',
            },
            description: 'A textbox that can be typed in, and writes the value to a variable',
            hints: {
                btn: 'Submit button text',
                inputLabel: 'Label for the input',
            },
            labels: {
                btn: 'Submit button text',
                captureFocus: 'Automatically capture key strokes',
                enabledVar: 'Enabled',
                errorVar: 'Error Message',
                inputLabel: 'Label for the input',
                onlyIfEmpty: 'Only capture if input is empty',
                trim: 'Trim whitespace around input',
                writeVar: 'Select a variable to write the input to',
            },
            title: 'Input',
        },
        'materialLookup': {
            description: 'Shows a table of material based on filter conditions',
            filters: {
                and: 'ALL',
                or: 'ANY',
                overview: 'Only include materials that satisfy {boolOperation} of the following filter conditions:',
                title: 'Material Filters',
            },
            outputVars: {
                description: 'Store the selected material data in these variables',
                materialId: 'Material ID',
                sn: 'Serial Number',
                title: 'Output Variables',

            },
            resetConditionalOverview: 'Refresh the list of material when {boolOperation} of the following conditions are met:',
            resetConditionalTitle: 'Refresh Condition',
            settingsTitle: 'Material Lookup Settings',
            title: 'Material Lookup Table',
        },
        'media': {
            description: 'Display a carousel from uploaded or linked media',
            title: 'Media',
        },
        'page': {
            autoAdvance: {
                condition: {
                    advanceToHelper: 'Page: {pageName}',
                    auto: 'the next available page',
                    conditionTypeHelper: 'Advance type: {pageTarget}',
                    description: 'Advance to {pageTarget} when {boolSelection} of the following comparisons are satisfied{dropDownBtn}{deleteBtn}',
                    spesificText: 'a specific page',
                },
                description: 'These rules determine when the next page should be shown, and what this next page will be. The first rule that matches will be used',
                page: 'Page: {pageName}',
                title: 'Page Switch Rules',
            },
            description: 'A page within a paged cell',
            disable: {
                condition: {
                    description: 'Disable this page when {boolSelection} of the following comparisons are satisfied{dropDownBtn}{deleteBtn}',
                },
                description: 'Disabling this page will prevent other pages from advancing to it. If any rules match the page will be disabled',
                hide: 'Hide page from screen when disabled',
                title: 'Page Disable Rules',
            },
            display: {
                condition: {
                    description: 'Show {displayPreview} status when {boolSelection} of the following comparisons are satisfied{dropDownBtn}{deleteBtn}',
                    error: 'an error',
                    info: 'text',
                    success: 'a completed page',
                    warn: 'a warning',
                },
                description: 'These rules affect the appearance of this page in the list. The first rule that matches will be used, if none of the rules match the default status will be used',
                title: 'Display Rules',
            },
            general: 'General Settings',
            outputVar: {
                description: 'This value is what will be shown on the app screen',
                title: 'Page Name',
            },
            title: 'Page',
        },
        'paged': {
            description: 'Shows a sequence of pages',
            outputVar: 'Store the current page number in a variable (optional)',
            reset: {
                condition: 'Reset the page sequence when {boolSelection} of the following conditions are true',
                description: 'When a reset condition is true the page sequence will reset to the first non-disabled page',
                title: 'Reset Conditions',
                vars: {
                    label: 'Select variables to reset when the page sequence gets reset',
                    toolTip: 'When a variable gets reset its value will be set to its initial value',
                },
            },
            title: 'Page Sequence',
        },
        'process': {
            description: 'Cell Used to display process data',
            title: 'Process Cell',
        },
        'scanner-mqtt-publish': {
            description: 'Text entry that gets published to an mqtt topic',
            title: 'Scanner Mqtt Publish',
        },
        'text': {
            description: 'Show text content',
            title: 'Text',
        },
        'timer': {
            description: 'Shows a value from a variable represented in a timer',
            title: 'Timer',
        },
    },
    defaultFont: 'Default Font',
    errors: {
        appConfig: {
            appletEventNotFound: (opts: { eventName: string, flowName: string }) => `Event with name "${opts.eventName}" does not exist. It is referenced in flow "${opts.flowName}"`,
            duplicateAppletEventName: (values: { eventName: string, cellName: string, cellTitle: string }) => `Event with name "${values.eventName}" on ${values.cellTitle} cell "${values.cellName || 'Unnamed'}" already exists`,
            duplicateVar: (values: { varName: string, providerName?: string, providerTitle: string }) => `Variable output "${values.varName}" in ${values.providerTitle} provider "${values.providerName || 'Unnamed'}" already exists`,
            invalidVarOrder: (values: { varName: string, providerName?: string, providerTitle: string }) => `Variable input "${values.varName}" in ${values.providerTitle} provider "${values.providerName || 'Unnamed'}" was used before it was defined.\n Check your variable providers and that they are in the appropriate order to reference their inputs.`,
            name: 'Config name is required',
            noAspectRatio: 'At least one screen size must be enabled',
            nonexistentVarCell: (values: { varName: string, cellType: string, cellLabel?: string }) => `Variable input "${values.varName}" referenced by ${values.cellType} cell "${values.cellLabel || 'Unnamed'}" does not exist.`,
            nonexistentVarVarp: (values: { varName: string, providerName?: string, providerTitle: string }) => `Variable input "${values.varName}" referenced by ${values.providerTitle} provider "${values.providerName || 'Unnamed'}" does not exist.`,
            scripts: {
                compileError: 'Script failed to compile',
                noId: 'A script must have an ID',
                notFound: 'A script cannot be empty',
                varNotFound: (varName: string) => `Applet variable "${varName}" referenced by script does not exist`,
            },
        },
    },
    hints: {
        modifiers: 'Select variables to modify cell attributes. When the variable has a value, it will override whatever the attribute\'s default value is, as configured above.',
        monacoEditor: {
            suggestions: {
                interpolates: [
                    {
                        name: 'ctrl',
                        type: 'code',
                        value: 'Ctrl',
                    },
                    {
                        name: 'space',
                        type: 'code',
                        value: 'space',
                    },
                ],
                message: 'Use {ctrl} + {space} to show suggestions',
            },
        },
        varNameSelection: 'Ctrl+click to go to original provider',
        weight: 'Controls the ratio of space given to this cell, relative to its siblings',
    },
    labels: {
        addScreen: 'Add Screen',
        appDevDevices: 'Active Devices',
        bannerHeader: 'Banner header',
        bgColor: 'Background Color',
        configLocation: 'Config Location',
        elevated: 'Elevated',
        lockout: 'Lockout Variable',
        margins: 'Margins',
        modifiers: 'Modifiers',
        padding: 'Padding',
        screenName: 'Screen Name',
    },
    macros: {
        process: {
            collectTrigger: {
                label: 'Process data collection will be triggered when...',
                triggers: {
                    btn: 'a button is pressed',
                    conditional: {
                        collectConditional: {
                            boolOperation: 'When {boolSelection} of the following comparisons are satisfied, do a data collect:',
                        },
                        label: 'the following conditions are met',
                    },
                    sparkplug: 'a sparkplug metric is changed',
                    var: 'a variable is changed',
                },
            },
            defaultSparkplugNode: 'Use the default sparkplug node for the process',
            sparkplugTrigger: {
                device: 'Sparkplug Device',
                metric: 'Metric',
            },
            varTriggerLabel: 'Variable to trigger data collection',
        },
    },
    migration: {
        apply: (mode: EntityDetailMode) => mode === 'fork' ? 'Fork and Apply Changes' : 'Apply Changes',
        applyAll: (mode: EntityDetailMode) => mode === 'fork' ? 'Fork and accept all changes' : 'Accept all changes and continue',
        bannerWarning: 'This config was created with a previous version of Argonaut. Viewing an older config might cause unexpected behavior.',
        description1: 'This configuration was created with a previous version of Argonaut and needs to be migrated to the latest version.',
        description2: 'Some things might not work as expected until the migrations are applied.',
        description3: 'Please review the following changes before continuing:',
        noReview: 'None of the migrations require your review.',
        skip: 'Skip Migrations and Proceed Anyway',
        title: 'Heads Up!',
        unknownType: 'Auto-apply migration',
    },
    options: {
        advancedSzControls: 'Sizing Controls (Advanced)',
        simpleSzControls: 'Sizing Controls (Simple)',
    },
    screenDesigner: {
        grid: {
            screenPreview: {
                noCells: {
                    subtitle: 'Use the grid modifier tool ({toolIcon}) to add a cell',
                    title: 'This screen has no cells',
                },
            },
            subtitle: 'Create/ modify the layout of cells for an applet screen',
            title: 'Cell Layout',
        },
        subtitle: 'Configure the design of an applet screen as well as assign Argonaut cell types to cells on the screen',
        title: 'Screen Maintenance',
        toolbarBtns: {
            default: 'Default',
            wireframe: 'Wireframe',
        },
    },
    sparkplugNodeDevice: {
        desc: 'Select which variables to expose as Sparkplug metrics',
        grid: {
            headers: {
                name: 'Variable Name',
                permissions: 'Allow Writes',
                track: 'Track Value?',
            },
            toolTips: {
                permissions: {
                    descs: [
                        {
                            interpolates: [
                                {
                                    name: 'customVarp',
                                    type: 'code',
                                    value: 'Custom',
                                },
                                {
                                    name: 'dcmd',
                                    type: 'code',
                                    value: 'DCMD',
                                },
                            ],
                            message: 'Only variables defined in a {customVarp} variable provider are allowed to be written to from a Sparkplug device command ({dcmd})',
                        },
                    ],
                    title: 'Allow Writes',
                },
                title: 'Help',
                track: {
                    descs: [
                        {
                            interpolates: [
                                {
                                    name: 'myVar',
                                    type: 'code',
                                    value: '#My+Variable+Name',
                                },
                                {
                                    name: 'formattedVar',
                                    type: 'code',
                                    value: '-My-Variable-Name',
                                },
                            ],
                            message: 'If a variable is tracked then a Sparkplug metric will be created mirroring the name of the variable it is tracking (variable names will be formatted to sparklug metric name standards. i.e. {myVar} -> {formattedVar})',
                        },
                        {
                            interpolates: [],
                            message: 'If a variable is not tracked then no Sparkplug metric will be created for it, and it will not be available to other Sparkplug devices',
                        },
                    ],
                    title: 'Track Variable',
                },
            },
        },
        noVars: 'This Design Does Not Have Any Defined Variables',
        title: 'Sparkplug Metrics',
    },
    titles: {
        baseCellAttrs: 'Base Cell Attributes',
        configEdit: 'Editing Config',
        general: 'General',
        overview: 'Overview',
        providers: 'Providers',
        screens: 'Screens',
    },
    tooltips: {
        forkConfig: (configName: string) => `Fork "${configName}"`,
    },
    varName: 'Variable Name',
    varProviders: {
        attr: {
            desc: 'Import data from a topic\'s attributes',
            title: 'Attributes',
        },
        calendar: {
            defaultTitle: 'Default Calendar Values',
            desc: 'Import data from a calendar',
            event: {
                configMessage: 'Get the {dataVar} value from the {occurance} and put the result in {varName}',
                defaultVal: '(optional) Default Value',
                getDataFromLabel: 'Get the value from',
                isUdf: 'Is UDF?',
                occurance: 'Event Occurance',
                title: 'Calendar Events',
                writeDataToLabel: 'Output variable to hold the data',
            },
            title: 'Calendar',
            udfTitle: 'Udf Calendar Values',
            validationErrs: {
                noLocation: 'A calendar provider must specify a location',
                noOutputVar: 'A calendar event must specify an output variable',
                noVarPrefix: 'A calendar variable provider must specify a variable prefix',
            },
            variables: {
                title: 'Calendar Variables',
            },
        },
        comparative: {
            addAssignment: 'Add Assignment',
            addComparison: 'Add Comparison',
            assignmentDescription: 'The first assignment with a successful condition will determine the variable\'s value. All assignments will be evaluated when any of the inputs change.',
            assignments: 'Conditional Assignments',
            boolOperation: {
                and: 'ALL',
                or: 'ANY',
            },
            comparison: {
                changedIgnoreNonOk: 'Only "ok" values',
                comparisonType: 'Comparison Type',
                comparisonTypes: {
                    changed: 'Value Was Changed',
                    eq: 'Equality',
                    gt: 'Greater Than',
                    isset: 'Is Set',
                    lt: 'Less Than',
                    regexp: 'RegExp Match',
                    type: 'Variable Value Type',
                },
                is: 'is',
                isNot: 'is not',
                not: 'NOT',
                operand: {
                    num: 'Number',
                    operand: 'Operand',
                    str: 'Text',
                    type: 'Operand Type',
                    var: 'Variable',
                },
                varOp: 'Variable Operand',
            },
            desc: 'Use logical conditions and comparisons to determine a variable value',
            initialAsFallbackLabel: 'Use a default value if none of the conditions are successful',
            inputType: {
                clear: 'be empty',
                constNum: 'the number',
                constStr: 'the text',
                var: 'another variable',
            },
            noDefaultDesc: 'Without a default value, if no conditions are met then the variable will keep its previous value, and be initially empty with a pending status',
            overview: 'If {anyAll} of the comparisons below are satisfied, then set the output value to {valueSelect}: {value}',
            targetVar: 'Output Variable Name',
            title: 'Comparative',
            trigger: {
                any: 'any inputs are changed',
                none: 'No inputs for any assignments have been defined yet',
                prefix: 'The assignments will be evaluated when',
                specific: 'specific inputs are changed',
            },
            validationErrs: {
                assignment: {
                    inputVar: 'a variable name to provide the assignment value is required',
                    invalid: (assignmentIndex: number) => `Assignment ${assignmentIndex + 1} is invalid`,
                    invalidComparison: (comparisonIndex: number) => `Comparison ${comparisonIndex + 1} is invalid`,
                    noComparisons: 'at least one comparison is required',
                    varName: 'an output variable name is required',
                    variableOperand: 'Variable operand cannot be left blank',
                },
                noAssignments: 'No assignments defined',
                triggerSources: 'If using specific inputs to trigger assignment evaluation, at least one input must be provided',
            },
            varBehavior: {
                defaultValueDescription: 'This is the value the output Variable will be set to if none of the above assignments match.',
                initialAsFallback: 'Initial as fallback',
                initialAsFallbackHelp: 'If no assignments succeed, use the initial state defined here as a fallback',
                initialStatus: 'Initial Status',
                initialValue: 'Initial Value',
                noOutputs: 'Add an assignment and provide a variable name to configure its behavior',
                statusPollution: 'Inherit Statuses',
                statusPollutionHelp: 'Statuses (pending/error) from operands/inputs can affect this variable\'s status',
                title: 'General',
            },
            varType: {
                existing: 'an existing custom',
                new: 'a new',
            },
        },
        createMaterial: createMaterialI18nMessages,
        dhl: {
            desc: 'Import device metrics from devices through Sparkplug',
            title: 'DHL',
        },
        formatted: {
            desc: 'Returns a formatted string from selected input Variables',
            duration: {
                description: 'Format the value of the variable as a standard duration format',
                examples: {
                    hours: formatDuration(3660, 'h' as DurationFormat),
                    minutes: formatDuration(1800, 'm' as DurationFormat),
                    seconds: formatDuration(205, 's' as DurationFormat),
                    stopWatch: formatDuration(3785, 'sw' as DurationFormat),
                },
                inputExamples: {
                    hours: '{td:exampleVarName:h}',
                    minutes: '{td:exampleVarName:m}',
                    seconds: '{td:exampleVarName:s}',
                    stopWatch: '{td:exampleVarName:sw}',
                },
                title: 'Duration Formatting Examples',
            },
            err: {
                outputVar: 'An output variable is required',
            },
            time: {
                description: 'Format the value of the variable as a standard time format',
                examples: {
                    longDate: formatTime(Date.now(), 'ld' as TimeFormat),
                    longDateTime: formatTime(Date.now(), 'l' as TimeFormat),
                    shortDate: formatTime(Date.now(), 'sd' as TimeFormat),
                    shortDateTime: formatTime(Date.now(), 's' as TimeFormat),
                    time: formatTime(Date.now(), 't' as TimeFormat),
                },
                inputExamples: {
                    longDate: '{t:exampleVarName:ld}',
                    longDateTime: '{t:exampleVarName:l}',
                    shortDate: '{t:exampleVarName:sd}',
                    shortDateTime: '{t:exampleVarName:s}',
                    time: '{t:exampleVarName:t}',
                },
                title: 'Time Formatting Examples',
            },
            title: 'Formatted',
        },
        local: {
            addVar: 'Add Variable',
            desc: 'Create variables to hold local state information',
            err: 'Variable names cannot be blank',
            title: 'Variables',
            var: {
                initial: 'Initial Value',
            },
        },
        locking: {
            addVariableToLock: 'Add Variable To Lock',
            desc: 'Limit the rate of Data Flow of Variables from a given input variable',
            hints: {
                locking: 'PUT DESC FOR LOCKING VAR HERE',
                output: (inputName: string) => `Output variable name for input ${inputName}`,
            },
            title: 'Locking',
        },
        macroTarget: {
            title: 'Macro Output',
        },
        materialId: {
            desc: 'Looks up a material\'s ID by serial number',
            errors: {
                noInputVar: 'A material lookup provider needs an input variable to get the serial number from',
                noOutputUdfVar: 'If a udf lookup is created an output variable for the udf value is required',
                noOutputVar: 'A material lookup provider needs an output variable to write the material ID to',
            },
            extraInfo: {
                desc: 'Get extra information about the material and output the data to variables (optional)',
                title: 'Extra Material Information',
                udfs: {
                    addClass: 'Add Material Class',
                    addClassError: 'One of the selected classes has already been added',
                    addOutputVar: 'Add Output Variable',
                    classDialog: {
                        title: 'Select Material Classes',
                    },
                    desc: 'Define what UDFs to get from a material based on the class that the material comes from',
                    dialog: {
                        title: 'Select A Udf Column',
                    },
                    noUdfs: 'No udfs for this material class',
                    nullValues: 'If the default value for a udf output is left blank, then the output variable will be empty ({null}) with an {ok} status',
                    title: 'Material Udfs',
                },
            },
            general: {
                title: 'General',
            },
            help: 'If a material is not found from the serial number input, or the input variable is not set, then the output variable will be empty ({null}) with an {ok} status',
            labels: {
                input: 'Input variable that will hold the serial number',
                output: 'Output Variable to hold the material ID',
                outputClassName: 'Output Variable to hold the class name',
                outputModelNumber: 'Output Variable to hold the model number',
            },
            title: 'Material Lookup',
        },
        mqttPublish: mqttPublishMessages,
        mqttSubscribe: mqttSubscribeMessages,
        process: {
            condition: 'When {boolSelection} of the following comparisons are satisfied, do a data collect:',
            desc: 'Execute a process, perform data collection, extract specification values and process state',
            err: {
                dcVars: 'Every data collection input needs a variable',
                invalidProcess: 'The selected process cannot be found',
                noMaterialId: 'No variable selected to provide the material ID',
                noProcess: 'Process Revision is required',
                trig: 'A trigger variable is required',
            },
            help: {
                paramVars: 'Optional variables to store solved process params',
                selectDcVars: 'Select variables to provide values for each data collection PDE',
                statusOutputs: 'Optional variables to store process state information',
            },
            hints: {
                dcVarsChanged: 'Data collection inputs for the selected process revision were changed',
                paramsChanged: 'Params for the selected process revision were changed',
                processRev: 'Changing this might clear the configured vars for data collection and spec params',
                stateVars: {
                    bad: '# of failed collection events',
                    good: '# of successful collection events',
                    required: '# of required collections events',
                    specId: 'The variable to hold the specs ID',
                    specName: 'The variable to hold the specs name',
                    status: 'The status of the process for the material',
                    validationError: 'The error message from a failed validation',
                },
                trigger: 'Data collection will only be performed when this value changes (or transitions to an "ok" status)',
            },
            labels: {
                dataCollection: 'Data Collection',
                matId: 'Variable to provide material ID',
                paramVars: 'Param Outputs',
                stateVars: {
                    bad: 'Bad count',
                    good: 'Good count',
                    required: 'Required count',
                    specId: 'Spec ID',
                    specName: 'Spec Name',
                    status: 'Process status',
                    validationError: 'Error message',
                },
                statusOutputs: 'Other Outputs',
                trigger: 'Data Collection Trigger',
            },
            title: 'Data Collect (Advanced)',
        },
        script: {
            boolCondition: 'When {boolSelection} of the following comparisons are satisfied, run the script:',
            desc: 'Create a script to apply to a variable',
            executeCfg: {
                custom: 'Custom',
                default: 'Default',
                getHelp: (type: 'custom' | 'default') => {
                    switch (type) {
                        case 'custom':
                            return 'The script will be executed whenever the execute condition is satisfied';
                        case 'default':
                            return 'The script will be executed whenever any variables used in the script change (aside from variables created by the script)';
                        default:
                            return '';
                    }
                },
                noVars: 'This script provider does not use any applet variables defined outside this script provider',
                title: 'Select script execution behavior:',
                trackValue: 'Track Value',
                variableName: 'Variable Name',
            },
            targetVars: 'Add Output Variable',
            title: 'Script',
            validationErrs: {
                circularReference: 'The output variable cannot be used as an input to the execute condition',
                executeCondition: 'At least one comparison is required',
                noInputs: 'At least one variable must be tracked for the script to execute',
                noOutputs: 'At least one output variable is required',
                scriptId: 'A script ID is required',
                targetVar: 'An output variable is required for a script provider',
            },
            variableModifyBehavior: {
                custom: {
                    title: 'Custom',
                    varsToModify: 'Variables to set after script execution',
                },
                default: {
                    title: 'Default',
                },
                getHelp: (type: 'custom' | 'default') => {
                    switch (type) {
                        case 'custom':
                            return 'The script can set the values of any variable defined in the "Variables" section of the applet';
                        case 'default':
                            return 'The script can only set the values of the variables that are defined by the script provider';
                        default:
                            return '';
                    }
                },
                title: 'Variable Modify Behavior',
            },
        },
        slice: {
            desc: 'Gives a section of text from another variable',
            minEnd: 'End must be greater than start, or blank',
            title: 'Slice',
        },
        sparkplugCommander: sparkplugDeviceWriteMessages,
        sparkplugConsumer: {
            desc: 'Import device metrics from devices through Sparkplug',
            title: 'Sparkplug Consumer',
        },
        tabGroupController: {
            desc: 'Automate the functionality and display of a tab group cell on a screen',
            title: 'Tab Group Controller',
            validationConfig: {
                evaluation: {
                    activate: 'If {anyAll} of the comparisons below are satisfied, then switch to this tab',
                    disable: 'If {anyAll} of the comparisons below are satisfied, then disable this tab',
                    display: 'If {anyAll} of the comparisons below are satisfied, then set the style of the tab to',
                },
            },
        },
        timer: timerMessages,
        udpSocket: udpSocketMessages,
        varStatus: {
            desc: 'Get the status of another variable',
            help: 'The output variable will be set to text "ok" if the input variable has a status of {ok}, "pending" if the input variable has a status of {pending}, or, if the input variable is in an {error} state, it will be set to the error\'s message',
            labels: {
                input: 'Which variable to extract the status from',
                output: 'Output variable to hold the status',
            },
            title: 'Variable Status',
        },
    },
    varStatus,
    varType,
    warnings: {
        monacoEditor: {
            main: 'WARNING: Scripts are powerful. Please carefully validate intended script purpose and implementation to minimize impact on overall system performance. Script API is likely to be extended and may change in the future. Current safeguard limitation on scripts ensures that script environements are limited to:',
            safeGaurds: [
                '8 MB of memory',
                '5 ms of execution time',
            ],
        },
    },
};
