import { ClassicPreset } from 'rete';
import {
    type NodeSocketKind,
    areSocketsCompatible,
} from '@redviking/argonaut-util/types/mes/applet-designs/flow.latest';

export class UiFlowNodeSocket extends ClassicPreset.Socket {
    kind: NodeSocketKind;
    constructor (kind: NodeSocketKind) {
        super(kind as string);
        this.kind = kind;
    }

    isCompatibleWith (socket: UiFlowNodeSocket) {
        return areSocketsCompatible(this.kind, socket.kind);
    }
}
