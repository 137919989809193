import {
    computed,
    getCurrentInstance,
} from 'vue';

/**
 * Provides reactive access to the current route
 *
 * @note differs from vue-router's `useRoute` in that this one returns a ref instead of a reactive object
 */
export function useRoute () {
    const vm = getCurrentInstance()?.proxy;
    if (!vm) {
        throw new Error('This must be called from within a setup fn');
    }
    return computed(() => vm.$route);
}
