import { accessor } from 'src/store';
import type { UiFlowNode } from '../UiFlowNode';
import type {
    NodeCfg,
    NodeKind,
    NodeSocketKindMap,
} from '@redviking/argonaut-util/types/mes/applet-designs/flow.latest';

/**
 * applies removing a socket from a node.
 *
 * any relevant connections are updated. and the node is rerendered by replacing its cfg object.
 *
 * This updates vuex, so update your node config before calling this.
 */
export function removeNodeSocket<K extends NodeKind, PORT extends 'inputs' | 'outputs'> (opts: {
    node: UiFlowNode<K>;
    newNodeCfg: NodeCfg<K>;
    socketToRemove: {
        /** how to derive the name */
        nameFn: (idx: number) => keyof NodeSocketKindMap[K][PORT];
        idx: number;
    };
    /** must be pre removal, so all existing sockets can be found and corrected */
    portSocketsTotal: number;
    port: PORT;
}): void {
    const {
        node,
        socketToRemove,
        newNodeCfg,
        portSocketsTotal,
        port,
    } = opts;

    const entityData = accessor.entityAsType('appletDesignVersion');
    if (!entityData) {
        return;
    }

    /** sockets that will be shifted (and their names changed) as a result of this removal */
    const socketNameMapping: Record<string, string> = {};
    for (let i = socketToRemove.idx + 1; i < portSocketsTotal; i++) {
        socketNameMapping[socketToRemove.nameFn(i) as string] = socketToRemove.nameFn(i - 1) as string;
    }

    const flowConnections = { ...node.flowCfgRef.value.connections };
    const connectionsOnNode = Object.entries(flowConnections).filter(([ _connId, c ]) => {
        if (port === 'inputs') {
            return c.targetNodeId === node.id;
        } else {
            return c.sourceNodeId === node.id;
        }
    });

    const socketNameToRemove = socketToRemove.nameFn(socketToRemove.idx);
    const connectionEntryToRemove = connectionsOnNode.find(([ _id, c ]) => {
        if (port === 'inputs') {
            return c.targetNodePort === socketNameToRemove;
        } else {
            return c.sourceNodePort === socketNameToRemove;
        }
    });
    if (connectionEntryToRemove) {
        delete flowConnections[connectionEntryToRemove[0]];
    }

    for (const [ _, conn ] of connectionsOnNode) {
        if (port === 'inputs' && conn.targetNodePort in socketNameMapping) {
            conn.targetNodePort = socketNameMapping[conn.targetNodePort];
        } else if (port === 'outputs' && conn.sourceNodePort in socketNameMapping) {
            conn.sourceNodePort = socketNameMapping[conn.sourceNodePort];
        }
    }

    accessor.setPageEntity({
        entity: {
            ...entityData,
            config: {
                ...entityData.config,
                flows: {
                    ...entityData.config.flows,
                    [node.flowCfgRef.value.id]: {
                        ...node.flowCfgRef.value,
                        connections: flowConnections,
                        nodes: {
                            ...node.flowCfgRef.value.nodes,
                            [node.id]: {
                                ...newNodeCfg,
                            },
                        },
                    },
                },
            },
        },
        type: 'appletDesignVersion',
    });
}
