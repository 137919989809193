/* eslint-disable max-lines */
/* eslint sort-keys: ["error"] */
import { z } from 'zod';
import { locationSchema } from '../../../location.deployParam';
import { ArgoUdfColumnDataTypeEnum } from '../../../../db';

const nodeKindSchema = z.enum([
    'slice',
    'number',
    'strCast',
    'numCast',
    'text',
    'boolCast',
    'mqttPublish',
    'appletEventEmit',
    'appletEventListen',
    'varRead',
    'udpRead',
    'varWrite',
    'varAssigned',
    'script',
    'jsonParse',
    'numClamp',
    'txtFormat',
    'defaultVal',
    'eventSwitch',
    'timer',
    'sparkplugConsumer',
    'udpWrite',
    'udpSocket',
    'start',
    'createMaterial',
    'eventDelay',
    'mqttSubscribe',
    'eventThrottle',
    'eventOneShot',
    'comparisonString',
    'comparisonNumber',
]);
export type NodeKind = z.infer<typeof nodeKindSchema>;

export const baseNodeCfgSchema = z.object({
    controlValues: z.record(z.unknown()).optional(),
    id: z.string(),
    kind: nodeKindSchema,
    label: z.string().optional(),
    /** overrides connecting a socket and provides a constant value */
    socketOverrides: z.object({
        inputs: z.record(z.unknown()).optional(),
        outputs: z.record(z.unknown()).optional(),
    }).optional(),
    width: z.number().optional(),
    x: z.number().optional(),
    y: z.number().optional(),
});
export interface BaseNodeCfg extends z.infer<typeof baseNodeCfgSchema> {}

export const nodeCfgSchemaMap = {
    appletEventEmit: baseNodeCfgSchema.extend({
        controlValues: z.object({
            appletEventId: z.string(),
        }),
        kind: z.literal('appletEventEmit'),
    }),
    appletEventListen: baseNodeCfgSchema.extend({
        controlValues: z.object({
            appletEventId: z.string(),
        }),
        kind: z.literal('appletEventListen'),
    }),
    boolCast: baseNodeCfgSchema.extend({
        kind: z.literal('boolCast'),
    }),
    comparisonNumber: baseNodeCfgSchema.extend({
        controlValues: z.object({
            not: z.boolean(),
            operation: z.enum([ '=', '>', '>=', '<', '<=' ]),
        }),
        kind: z.literal('comparisonNumber'),
    }),
    comparisonString: baseNodeCfgSchema.extend({
        controlValues: z.object({
            not: z.boolean(),
            operation: z.enum([ '=', 'contains', 'startsWith', 'endsWith' ]),
        }),
        kind: z.literal('comparisonString'),
    }),
    createMaterial: baseNodeCfgSchema.extend({
        controlValues: z.object({
            allowExistingMaterial: z.boolean(),
            attributes: z.record(z.string(), z.object({
                name: z.string(),
                schema_id: z.string(),
            })),
            labelPrint: z.boolean(),
            location: locationSchema,
            materialClassId: z.string(),
            materialModelId: z.string(),
            snCfg: z.discriminatedUnion('kind', [
                z.object({ kind: z.literal('direct') }),
                z.object({
                    fmtInputs: z.array(z.string()), // this is to generate the inputs for the formatter
                    kind: z.literal('formatter'),
                }),
            ]),
            udfs: z.record(z.string(), z.object({
                name: z.string(),
                required: z.boolean(),
                type: z.nativeEnum(ArgoUdfColumnDataTypeEnum),
            })),
        }),
        kind: z.literal('createMaterial'),
    }),
    defaultVal: baseNodeCfgSchema.extend({
        kind: z.literal('defaultVal'),
    }),
    eventDelay: baseNodeCfgSchema.extend({
        controlValues: z.object({
            delayMs: z.number(),
        }),
        kind: z.literal('eventDelay'),
    }),
    eventOneShot: baseNodeCfgSchema.extend({
        kind: z.literal('eventOneShot'),
    }),
    eventSwitch: baseNodeCfgSchema.extend({
        kind: z.literal('eventSwitch'),
    }),
    eventThrottle: baseNodeCfgSchema.extend({
        controlValues: z.object({
            max: z.number(),
            windowMs: z.number(),
        }),
        kind: z.literal('eventThrottle'),
    }),
    jsonParse: baseNodeCfgSchema.extend({
        controlValues: z.object({
            outputExpressions: z.array(z.string().regex(/^[0-9a-z]+$/ui, 'Only simple identifiers are allowed in output expressions')),
        }),
        kind: z.literal('jsonParse'),
    }),
    mqttPublish: baseNodeCfgSchema.extend({
        controlValues: z.object({
            qos: z.union([
                z.literal(0),
                z.literal(1),
                z.literal(2),
            ]),
            retain: z.boolean(),
        }),
        kind: z.literal('mqttPublish'),
    }),
    mqttSubscribe: baseNodeCfgSchema.extend({
        controlValues: z.object({
            qos: z.union([
                z.literal(0),
                z.literal(1),
                z.literal(2),
            ]),
            topic: z.string(),
        }),
        kind: z.literal('mqttSubscribe'),
    }),
    numCast: baseNodeCfgSchema.extend({
        kind: z.literal('numCast'),
    }),
    numClamp: baseNodeCfgSchema.extend({
        controlValues: z.object({
            max: z.number().optional(),
            min: z.number().optional(),
        }),
        kind: z.literal('numClamp'),
    }),
    number: baseNodeCfgSchema.extend({
        controlValues: z.object({
            value: z.number(),
        }),
        kind: z.literal('number'),
    }),
    script: baseNodeCfgSchema.extend({
        controlValues: z.object({
            scriptId: z.string(),
        }),
        kind: z.literal('script'),
    }),
    slice: baseNodeCfgSchema.extend({
        kind: z.literal('slice'),
    }),
    sparkplugConsumer: baseNodeCfgSchema.extend({
        controlValues: z.object({
            deviceId: z.string(),
            groupId: z.string(),
            metricExpressions: z.array(z.string()),
            nodeId: z.string(),
        }),
        kind: z.literal('sparkplugConsumer'),
    }),
    start: baseNodeCfgSchema.extend({
        kind: z.literal('start'),
    }),
    strCast: baseNodeCfgSchema.extend({
        kind: z.literal('strCast'),
    }),
    text: baseNodeCfgSchema.extend({
        controlValues: z.object({
            value: z.string(),
        }),
        kind: z.literal('text'),
    }),
    timer: baseNodeCfgSchema.extend({
        controlValues: z.object({
            dir: z.union([ z.literal('up'), z.literal('down') ]),
            /** the number the timer should stop at */
            limit: z.number(),
        }),
        kind: z.literal('timer'),
    }),
    txtFormat: baseNodeCfgSchema.extend({
        controlValues: z.object({
            formatTemplate: z.string(),
            inputs: z.number(),
        }),
        kind: z.literal('txtFormat'),
    }),
    udpRead: baseNodeCfgSchema.extend({
        controlValues: z.object({
            encoding: z.union([ z.literal('utf8'), z.literal('hex') ]),
            hostInterface: z.string().min(1),
            port: z.number().min(1024),
        }),
        kind: z.literal('udpRead'),
    }),
    udpSocket: baseNodeCfgSchema.extend({
        controlValues: z.object({
            bindAddress: z.string(),
            bindPort: z.number(),
            connectToAddress: z.string(),
            connectToPort: z.number(),
            encoding: z.union([ z.literal('utf8'), z.literal('hex'), z.literal('base64') ]),
        }),
        kind: z.literal('udpSocket'),
    }),
    udpWrite: baseNodeCfgSchema.extend({
        controlValues: z.object({
            host: z.string().min(1),
            port: z.number().min(1024),
        }),
        kind: z.literal('udpWrite'),
    }),
    varAssigned: baseNodeCfgSchema.extend({
        controlValues: z.object({
            changesOnly: z.boolean(),
            varName: z.string(),
        }),
        kind: z.literal('varAssigned'),
    }),
    varRead: baseNodeCfgSchema.extend({
        controlValues: z.object({
            varName: z.string(),
        }),
        kind: z.literal('varRead'),
    }),
    varWrite: baseNodeCfgSchema.extend({
        controlValues: z.object({
            varName: z.string(),
        }),
        kind: z.literal('varWrite'),
    }),
} satisfies {
    // ensure all node schemas don't override anything from baseNodeCfgSchema, and all node kinds are covered.
    [nodeKind in NodeKind]: z.ZodType<BaseNodeCfg & { kind: NodeKind }>;
};

export const nodeCfgSchema: z.ZodType<BaseNodeCfg> = baseNodeCfgSchema.superRefine((val, ctx) => {
    // this will check the specific schema for the node kind.
    // by not using discriminated union, we can easily ensure that all node kinds are covered,
    // just by propagating the issues up.

    // also we can validate that the socket overrides are valid.

    const specificSchema = nodeCfgSchemaMap[val.kind];
    const parseResult = specificSchema.safeParse(val);
    if (!parseResult.success) {
        const nodeTitle = val.label ? `${val.kind} node "${val.label}"` : `${val.kind} node`;
        for (const issue of parseResult.error.issues) {
            ctx.addIssue({
                ...issue,
                fatal: true,
                message: `${nodeTitle} - ${issue.message}`,
            });
        }
        return z.NEVER;
    }

    // TODO validate socketOverrides

    return true;
});

export type NodeCfg<KIND extends NodeKind> = z.infer<typeof nodeCfgSchemaMap[KIND]>;

export type NodeSocketKind = 'string' | 'number' | 'varValue' | 'boolean' | 'event';

type SocketCategory = 'control' | 'data';
export const socketCategoryMap = {
    boolean: 'data',
    event: 'control',
    number: 'data',
    string: 'data',
    varValue: 'data',
} satisfies {
    [kind in NodeSocketKind]: SocketCategory;
};
export type SocketCategoryMap = typeof socketCategoryMap;

export type ControlSocketKind = {
    [K in NodeSocketKind]: SocketCategoryMap[K] extends 'control' ? K : never;
}[NodeSocketKind];

export type DataSocketKind = {
    [K in NodeSocketKind]: SocketCategoryMap[K] extends 'data' ? K : never;
}[NodeSocketKind];

export type NodeSocketDef<KIND extends NodeKind, ALLOWED_SOCKET_KINDS extends NodeSocketKind> = {
    inputs: (cfg: NodeCfg<KIND>) => Record<string, ALLOWED_SOCKET_KINDS>;
    outputs: (cfg: NodeCfg<KIND>) => Record<string, ALLOWED_SOCKET_KINDS>;
};

/**
 * a mapping from node kind to its sockets
 * @note it should be assumed that the value of every input is nullable
 * @TODO this could be more concise, I'm not sure we need to separate the control and data socket objects here, because we can identify the control sockets with the `event` kind.
 */
export const nodeSocketKindMap = {
    appletEventEmit: {
        controlSockets: {
            inputs: () => ({ trigger: 'event' }),
            outputs: () => ({ next: 'event' }),
        },
        dataSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
    },
    appletEventListen: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({ event: 'event' }),
        },
        dataSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
    },
    boolCast: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
        dataSockets: {
            inputs: () => ({ input: 'varValue' }),
            outputs: () => ({ output: 'boolean' }),
        },
    },
    comparisonNumber: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
        dataSockets: {
            inputs: () => ({ input1: 'varValue', input2: 'varValue' }),
            outputs: () => ({ output: 'boolean' }),
        },
    },
    comparisonString: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
        dataSockets: {
            inputs: () => ({ input1: 'varValue', input2: 'varValue' }),
            outputs: () => ({ output: 'boolean' }),
        },
    },
    createMaterial: {
        controlSockets: {
            inputs: () => ({ trigger: 'event' }),
            outputs: () => ({ next: 'event' }),
        },
        dataSockets: {
            inputs: (cfg: NodeCfg<'createMaterial'>): Record<`udfInput_${string}` | 'sn' | `snInput_${string}`, 'varValue'> => {
                const inputs = {} as Record<`udfInput_${string}` | 'sn' | `snInput_${string}`, 'varValue'>;

                for (const udfId in cfg.controlValues.udfs) {
                    inputs[`udfInput_${udfId}`] = 'varValue';
                }

                if (cfg.controlValues.snCfg.kind === 'direct') {
                    inputs.sn = 'varValue';
                } else {
                    for (const inputId in cfg.controlValues.snCfg.fmtInputs) {
                        inputs[`snInput_${inputId}`] = 'varValue';
                    }
                }

                return inputs;
            },
            outputs: () => ({ materialId: 'string', serialNumber: 'string' }),
        },
    },
    defaultVal: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
        dataSockets: {
            inputs: () => ({ defaultValue: 'varValue', input: 'varValue' }),
            outputs: () => ({ output: 'varValue' }),
        },
    },
    eventDelay: {
        controlSockets: {
            inputs: () => ({ input: 'event' }),
            outputs: () => ({ output: 'event' }),
        },
        dataSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
    },
    eventOneShot: {
        controlSockets: {
            inputs: () => ({ input: 'event', reset: 'event' }),
            outputs: () => ({ output: 'event' }),
        },
        dataSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
    },
    eventSwitch: {
        controlSockets: {
            inputs: () => ({ event: 'event' }),
            outputs: () => ({ false: 'event', true: 'event' }),
        },
        dataSockets: {
            inputs: () => ({ condition: 'boolean' }),
            outputs: () => ({}),
        },
    },
    eventThrottle: {
        controlSockets: {
            inputs: () => ({ input: 'event' }),
            outputs: () => ({ output: 'event' }),
        },
        dataSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
    },
    jsonParse: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
        dataSockets: {
            inputs: () => ({ input: 'string' }),
            outputs: (cfg: NodeCfg<'jsonParse'>): Record<`output${number}`, 'varValue'> => {
                const outputEntries = cfg.controlValues.outputExpressions.map((_, i) => [ `output${i}`, 'varValue' ]);
                return Object.fromEntries(outputEntries);
            },
        },
    },
    mqttPublish: {
        controlSockets: {
            inputs: () => ({ trigger: 'event' }),
            outputs: () => ({ next: 'event' }),
        },
        dataSockets: {
            inputs: () => ({ payload: 'string', topic: 'string' }),
            outputs: () => ({}),
        },
    },
    mqttSubscribe: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({ event: 'event' }),
        },
        dataSockets: {
            inputs: () => ({}),
            outputs: () => ({ payload: 'string' }),
        },
    },
    numCast: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
        dataSockets: {
            inputs: () => ({ input: 'varValue' }),
            outputs: () => ({ output: 'number' }),
        },
    },
    numClamp: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
        dataSockets: {
            inputs: () => ({ input: 'number' }),
            outputs: () => ({ output: 'number' }),
        },
    },
    number: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
        dataSockets: {
            inputs: () => ({}),
            outputs: () => ({ output: 'number' }),
        },
    },
    script: {
        controlSockets: {
            inputs: () => ({ trigger: 'event' }),
            outputs: () => ({ next: 'event' }),
        },
        dataSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
    },
    slice: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
        dataSockets: {
            inputs: () => ({ end: 'number', input: 'string', start: 'number' }),
            outputs: () => ({ output: 'string' }),
        },
    },
    sparkplugConsumer: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({ event: 'event' }),
        },
        dataSockets: {
            inputs: () => ({}),
            outputs: (cfg: NodeCfg<'sparkplugConsumer'>): Record<`output${number}`, 'varValue'> => {
                const outputEntries = cfg.controlValues.metricExpressions.map((_, i) => [ `output${i}`, 'varValue' ]);
                return Object.fromEntries(outputEntries);
            },
        },
    },
    start: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({ next: 'event' }),
        },
        dataSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
    },
    strCast: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
        dataSockets: {
            inputs: () => ({ input: 'varValue' }),
            outputs: () => ({ output: 'string' }),
        },
    },
    text: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
        dataSockets: {
            inputs: () => ({}),
            outputs: () => ({ output: 'string' }),
        },
    },
    timer: {
        controlSockets: {
            inputs: () => ({ reset: 'event', start: 'event', stop: 'event' }),
            outputs: () => ({ done: 'event', tick: 'event' }),
        },
        dataSockets: {
            inputs: () => ({ initialValue: 'number' }),
            outputs: () => ({ value: 'number' }),
        },
    },
    txtFormat: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
        dataSockets: {
            inputs: (cfg: NodeCfg<'txtFormat'>): Record<`input${number}`, 'varValue'> => {
                const inputEntries: [`input${number}`, 'varValue'][] = [];
                for (let i = 0; i < cfg.controlValues.inputs; i++) {
                    inputEntries.push([ `input${i}`, 'varValue' ]);
                }
                return Object.fromEntries(inputEntries);
            },
            outputs: () => ({ output: 'string' }),
        },
    },
    udpRead: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({ event: 'event' }),
        },
        dataSockets: {
            inputs: () => ({}),
            outputs: () => ({ payload: 'string' }),
        },
    },
    udpSocket: {
        controlSockets: {
            inputs: () => ({ inputTrigger: 'event' }),
            outputs: () => ({
                onReceivedTrigger: 'event',
                onSentTrigger: 'event',
            }),
        },
        dataSockets: {
            inputs: () => ({ sendPayload: 'string' }),
            outputs: () => ({ receivePayload: 'string' }),
        },
    },
    udpWrite: {
        controlSockets: {
            inputs: () => ({ trigger: 'event' }),
            outputs: () => ({ next: 'event' }),
        },
        dataSockets: {
            inputs: () => ({ payload: 'string' }),
            outputs: () => ({}),
        },
    },
    varAssigned: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({ trigger: 'event' }),
        },
        dataSockets: {
            inputs: () => ({}),
            outputs: () => ({ newValue: 'varValue', oldValue: 'varValue' }),
        },
    },
    varRead: {
        controlSockets: {
            inputs: () => ({}),
            outputs: () => ({}),
        },
        dataSockets: {
            inputs: () => ({}),
            outputs: () => ({ value: 'varValue' }),
        },
    },
    varWrite: {
        controlSockets: {
            inputs: () => ({ trigger: 'event' }),
            outputs: () => ({ next: 'event' }),
        },
        dataSockets: {
            inputs: () => ({ value: 'varValue' }),
            outputs: () => ({}),
        },
    },
} satisfies {
    [kind in NodeKind]: {
        controlSockets: NodeSocketDef<kind, ControlSocketKind>;
        dataSockets: NodeSocketDef<kind, DataSocketKind>;
    };
};

export const compatibilityMatrix: Record<NodeSocketKind, NodeSocketKind[]> = {
    boolean: [
        'boolean',
        // 'varValue', // excluding for now because I'm not sure how vars will handle boolean values
    ],
    event: [ 'event' ],
    number: [ 'number', 'varValue' ],
    string: [ 'string', 'varValue' ],
    varValue: [ 'varValue' ],
};

export function areSocketsCompatible (a: NodeSocketKind, b: NodeSocketKind): boolean {
    return compatibilityMatrix[a].includes(b);
}

export function getNodeSocketMaps<KIND extends NodeKind> (cfg: NodeCfg<KIND>) {
    const dataSocketMapItem = nodeSocketKindMap[cfg.kind].dataSockets as NodeSocketDef<KIND, NodeSocketKind>;
    const controlSocketMapItem = nodeSocketKindMap[cfg.kind].controlSockets as NodeSocketDef<KIND, NodeSocketKind>;
    return {
        inputs: {
            ...dataSocketMapItem.inputs(cfg),
            ...controlSocketMapItem.inputs(cfg),
        },
        outputs: {
            ...dataSocketMapItem.outputs(cfg),
            ...controlSocketMapItem.outputs(cfg),
        },
    };
}
