import { ClassicPreset } from 'rete';
import type { UiFlowNodeControl } from '../controls/UiFlowNodeControl';

export class UiFlowNodeInput<S extends ClassicPreset.Socket> extends ClassicPreset.Input<S> {
    control: UiFlowNodeControl<unknown> | null;

    constructor (opts: {
        socket: S,
        label?: string,
        multipleConnections?: boolean,
        control?: UiFlowNodeControl<unknown>,
    }) {
        super(opts.socket, opts.label, opts.multipleConnections ?? true);
        this.control = opts.control || null;
    }
}
