import { z } from 'zod';
import { cellSchema } from './cells.zod';
import { varpSchema } from './var-providers.zod';
import { macroSchema } from './macros.zod';
import { varOrConstSchema } from './var-providers/var-provider.zod';
import { flowCfgSchema } from './flow/flow.zod';
import { appletEventDefSchema } from './event.zod';
import { strVarSchema } from './var-providers/var.zod';

const rowSchema = z.object({
    weight: z.number().default(1),
});

const columnSchema = z.object({
    weight: z.number().default(1),
});

const coordinateSchema = z.object({
    row: z.number().default(0),
    column: z.number().default(0),
});

const marginPaddingBaseSchema = z.object({
    top: z.number().default(0),
    right: z.number().default(0),
    bottom: z.number().default(0),
    left: z.number().default(0),
});

const borderSchema = z.object({
    width: z.number().default(0),
    color: z.string().default('black'),
    style: z.string().default('none'),
});

const borderStyleSchema = z.object({
    top: borderSchema,
    bottom: borderSchema,
    left: borderSchema,
    right: borderSchema,
});

export const baseGridCellSchema = z.object({
    id: z.string().uuid(),
    borderRadius: z.number(),
    border: borderStyleSchema,
    name: z.string().default(''),
    margin: marginPaddingBaseSchema,
    parentGridId: z.string().uuid(),
    elevation: z.number().default(0),
    padding: marginPaddingBaseSchema,
    coordinates: z.array(coordinateSchema),
    backgroundColor: varOrConstSchema(strVarSchema),
    additionalCellProperties: z.record(z.union([ z.string(), z.number(), z.boolean() ])).optional(),
});

export const regularGridCellSchema = baseGridCellSchema.extend({
    type: z.literal('regular'),
    additionalCellProperties: z.object({
        argoCellId: z.string().default(''),
    }),
});

const infoBoxGridCellSchema = baseGridCellSchema.extend({
    type: z.literal('infoBox'),
    additionalCellProperties: z.object({
        headerFontSize: z.number().default(16),
        headerSourceVar: varOrConstSchema(),
        contentSourceVar: varOrConstSchema(),
        contentFontSize: z.number().default(14),
        headerHorizontalAlignment: z.enum([ 'left', 'center', 'right' ]).default('left'),
        contentHorizontalAlignment: z.enum([ 'left', 'center', 'right' ]).default('left'),
        headerFontWeight: z.enum([ 'normal', 'bold', 'bolder', 'lighter' ]).default('normal'),
        contentFontWeight: z.enum([ 'normal', 'bold', 'bolder', 'lighter' ]).default('normal'),
        headerVerticalAlignment: z.enum([ 'flex-start', 'center', 'flex-end' ]).default('flex-start'),
        contentVerticalAlignment: z.enum([ 'flex-start', 'center', 'flex-end' ]).default('flex-start'),
    }),
});

const tabGroupCellSchema = baseGridCellSchema.extend({
    type: z.literal('tabGroup'),
    additionalCellProperties: z.object({
        tabSequenceController: z.string(),
        fixedTabs: z.boolean().default(false),
        verticalTabs: z.boolean().default(false),
        headerVerticalAlignment: z.enum([ 'top', 'bottom' ]).default('top'),
        headerHorizontalAlignment: z.enum([ 'left', 'right' ]).default('left'),
        tabHeaderSize: z.enum([ 'hidden', 'small', 'medium', 'large', 'x-large' ]).default('medium'),
    }),
});

const baseSubGridCellSchema = baseGridCellSchema.extend({
    rows: z.array(rowSchema),
    columns: z.array(columnSchema),
});

const subGridCellSchema = baseSubGridCellSchema.extend({
    type: z.literal('subGrid'),
});

const tabInstanceCellSchema = baseSubGridCellSchema.extend({
    idx: z.number(),
    type: z.literal('tabInstance'),
    additionalCellProperties: z.object({
        label: z.string(),
    }),
});

const linkedGridCellSchema = z.discriminatedUnion('type', [ regularGridCellSchema, subGridCellSchema, tabInstanceCellSchema, tabGroupCellSchema, infoBoxGridCellSchema ]);

const gridLayoutSchema = z.object({
    enabled: z.boolean().default(true),
    linkedGridCells: z.array(linkedGridCellSchema),
    gridConfig: baseSubGridCellSchema.omit({
        coordinates: true,
        parentGridId: true,
        additionalCellProperties: true,
    }),
});

export const gridLayoutAspectRatioSchema = z.object({
    mobile: gridLayoutSchema,
    tablet: gridLayoutSchema,
    desktop: gridLayoutSchema,
    largeDisplay: gridLayoutSchema,
});

export const screenSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    order: z.number().optional(),
    operatorAuth: z.object({
        /**
         * whether this screen participates in operator auth.
         * @default false
         */
        required: z.boolean().default(false),
    }),
    /**
     * Cells that are used within the screen
     */
    cells: z.array(z.lazy(() => cellSchema)),
    gridLayoutAspectRatios: gridLayoutAspectRatioSchema,
});

export const runtimeScriptCfgSchema = z.object({
    id: z.string(),
    script: z.string(),
    /**
     * disables validation against the "argo lang" grammar.
     *
     * this option is "hidden" and intentionally not made visible to users
     */
    disableGrammarValidation: z.boolean().optional(),
    label: z.string().optional(),
});

const sparkplugDeviceMetricPermissionsSchema = z.object({
    write: z.boolean(),
});

const sparkplugDeviceMetricSchema = z.object({
    name: z.string(),
    permissions: sparkplugDeviceMetricPermissionsSchema,
});

export const appletDesignVersionConfigSchema_v3 = z.object({
    header: z.string(),
    /**
     * specifies a list of providers, which provide variables to the config.
     * variables can be defined in terms of other variables.
     * to avoid circular loops, this is an ordered list and variables will only be able to reference earlier defined variables.
     */
    varProviders: z.array(varpSchema),
    /** holds the name of a variable. if set, the config should only be allowed to change when this variable's value changes, or lacks a value entirely */
    lockout: z.string().nullable(),
    screens: z.array(screenSchema),
    /**
     * if null, operator auth in general is not required for this config anywhere
     */
    operatorAuth: z.object({
        /**
         * the provider (from the auth service) that operators should use to authenticate
         * @default 'badge'
         */
        type: z.literal('badge'),
        /**
         * if null, the operator auth will not expire
         * @default null
         */
        expiration: z.object({
            timeoutMinutes: z.number(),
            /**
             * whether re-authentication should only be required after inactivity of `timeoutMinutes`,
             * or just since the last auth
             *
             * @default true
             */
            inactivityOnly: z.boolean().default(true),
        }).nullable().default(null),
    }).nullable(),
    macros: z.array(macroSchema),
    /**
     * The config schema version, should be changed every time there's breaking change in the config typedefs
     */
    schemaVersion: z.literal(3),
    sparkplugDeviceMetrics: z.array(sparkplugDeviceMetricSchema).default([]),
    scripts: z.array(runtimeScriptCfgSchema).default([]),
    fontFamily: z.union([
        z.literal('serif'),
        z.literal('sans-serif'),
        z.literal('monospace'),
        z.literal('cursive'),
        z.literal('fantasy'),
        z.literal('system-ui'),
    ]).default('monospace'),
    flows: z.record(flowCfgSchema).default({}),
    /**
     * All the user defined applet events,
     * mapping event IDs to their user defined names.
     *
     * Other implied (non user-defined) events will be possible, such as a button cell press event.
     */
    userAppletEvents: z.record(appletEventDefSchema),
});

export type AppletDesignVersionConfig_V3 = z.infer<typeof appletDesignVersionConfigSchema_v3>;
