/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-depth */
import { Latest, latestOkVarValSchema } from '../../types/mes/applet-designs/appletDesign.latest.zod';
import { CausalError } from '@redviking/causal-error';

export const okAppletVarHelper = (val: Latest.OkAppletVarVal): Latest.OkAppletVar => {
    if (val === null) {
        return { status: 'ok', val, type: 'null' };
    } else if (typeof val === 'string') {
        return { status: 'ok', val, type: 'str' };
    } else if (typeof val === 'number') {
        return { status: 'ok', val, type: 'num' };
    } else {
        return { status: 'ok', val, type: 'bool' };
    }
};

export const maybeOkAppletVarHelper = (val: unknown): Latest.AppletVar => {
    for (const validVarValueType of latestOkVarValSchema) {
        try {
            const parsedVarVal = validVarValueType.parse(val);
            return okAppletVarHelper(parsedVarVal);
        } catch (e: any) {
            // invalid_type is expected since a variable can only be one type all others will fail. If the error is something else we would want to report that
            if ('issues' in e && Array.isArray(e.issues)) {
                for (const err of e.issues) {
                    if ('code' in err) {
                        if (err.code !== 'invalid_type') {
                            return { status: 'error', val: new CausalError('unknown zod error', err), type: 'error' };
                        }
                    } else {
                        return { status: 'error', val: new CausalError('unknown error', err), type: 'error' };
                    }
                }
            } else {
                // something other than a zod error occurred
                return { status: 'error', val: new CausalError('unknown error', e), type: 'error' };
            }
            // else ignore because we are trying to parse the value with multiple schemas
        }
    }
    return { status: 'error', val: new CausalError('Invalid var value'), type: 'error' };
};

export const camelToTitle = (camel: string) => camel.replace(/([A-Z])/gu, ' $1').replace(/^./u, (str) => str.toUpperCase());

/**
 * show desktop by default, if desktop is disabled the following will be checked in order: mobile, tablet, largeDisplay
 * if the object passed in is null it will return desktop
*/
export const getDefaultAspectRatio = (gridLayoutAspectRatios?: Latest.Screen.Designer.GridLayoutAspectRatios): Latest.Screen.Designer.GridLayoutAspectRatio => {
    if (!gridLayoutAspectRatios) {
        return 'desktop';
    }
    if (!gridLayoutAspectRatios.desktop.enabled) {
        if (gridLayoutAspectRatios.mobile.enabled) {
            return 'mobile';
        } else if (gridLayoutAspectRatios.tablet.enabled) {
            return 'tablet';
        } else {
            return 'largeDisplay';
        }
    }
    return 'desktop';
};
